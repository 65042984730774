export default {
  common: {
    accessDenied: 'Access denied',
    pleaseSelect: 'Please select ',
    pleaseEnter: 'Please enter ',
    select: 'Select',
    StartDate: 'Start date',
    StartTime: 'Start time',
    toDate: 'To',
    Pleaseenter: 'Please enter',
    EndDate: 'End date',
    EndTime: 'End time',
    operations: 'Operations',
    detail: 'Detail',
    edit: 'Edit',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    submit: 'Submit',
    close: 'Close',
    sure: 'Sure',
    index: 'No.',
    delete: 'Delete',
    view: 'View',
    status: 'Status',
    Search: 'Search',
    Reset: 'Reset',
    clear: 'Clear',
    save: 'Save',
    noData: 'No data',
    tips: 'Tips',
    addBtn: 'Add',
    warning: 'Warning ',
    ReceiveSample: 'Receive samples ',
    ReturnSample: 'Return sample ',
    GetSample: 'Sample ',
    SendBack: 'Send back ',
    add: 'Add',
    confirmDelete: 'Are you sure you want to delete? ',
    back: 'Back',
    title: 'Title',
    noFile: 'No file',
    Next: 'Next step',
    export: 'Export',
    import: 'Import',
    agree: 'Agree',
    files: 'File upload',
    table: 'information table ',
    uploadFile: 'Drag and drop files into the area or click Upload ',
    uploadFormat: 'Upload jpg/jpeg/png/gif/pdf with a maximum size of 3MB and upload up to 10 files',
    uploadNum1: 'The number of uploaded files cannot exceed',
    uploadNum2: '',
    uploadSise: 'The size of the uploaded file cannot exceed',
    uploadType1: 'File format is incorrect, please upload',
    uploadType2: 'file formats',
    uploadTip1: 'Please upload',
    uploadTip2: 'a file with a size not larger than',
    uploadTip3: 'and a format of',
    uploadTip4: '',
    uploadFail: 'Upload failed, please try again',
    confirmDeleteAll: 'Are you sure you want to clear everything?',
    filtrate: 'Filtrate',
    columnSettings: 'Column Settings',
    unknownError: 'unknown error',
    sort: 'Sort',
    rfcNo: 'RFC No.',
    id: 'ID',
    year: 'Pick a year',
    mergeSubmit: 'Please save the composition report',
    exportSuccessful: 'Export successful',
    exportFailure: 'Export failure',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    generate: 'Generate',
    completeInfo: 'Please complete the ',
    completeRequired: ' required field'
  },
  request: {
    401: 'Unauthorized',
    403: 'Permission denied',
    404: 'Request error, the resource was not found',
    405: 'Request method not allowed',
    408: 'Request timeout',
    500: 'Server side error',
    501: 'Network not implemented',
    502: 'Network error',
    503: 'Service unavailable',
    504: 'Network timeout',
    505: 'The http version does not support this request',
    linkError: 'Connection error',
    timeout: 'Server response timed out, please try again later',
    linkServerError: 'Failed to connect to server'
  },
  form: {
    pleaseInput: 'Please enter content',
    sealNo: 'sealNo is six in total',
    inputNumber: 'Corresponding Certificate of Registration (SOR)/Certificate of License (SOL)/Product Certificate (PC) number'
  },
  header: {
    outLogin: 'Exit succeeded',
    exit: 'Exit',
    exitText: 'Are you sure you want to exit the current user?',
    AccountInformation: 'Account Information',
    ChangePassword: 'Change Password',
    LogOut: 'Logout',
    systemName: 'Inspection management system',
    businessType: 'Business type',
    oldVersion: 'Switch old version'
  },
  login: {
    slogan: 'We are WSCT',
    describe:
      '——An internationally recognized testing, certification, appraisal, and inspection agency',
    signInTitle: 'Sign in',
    accountLogin: 'Account sign in',
    mobileLogin: 'Mobile sign in',
    rememberMe: 'Remember me',
    Scancodelogin: 'Scan code login',
    forgetPassword: 'Forget password',
    signIn: 'Sign in',
    signInOther: 'Sign in with',
    userPlaceholder: 'user',
    userError: 'Please input a user name',
    PWPlaceholder: 'Please input a password',
    PWError: 'Please input a password',
    PWnum: 'Password must least 6 digits',
    Codelaceholder: 'Please input a code',
    CodeError: 'Please input a code',
    errorcode: 'Code error',
    admin: 'Administrator',
    user: 'User',
    mobilePlaceholder: 'Mobile',
    mobileError: 'Please input mobile',
    smsPlaceholder: 'SMS Code',
    smsError: 'Please input sms code',
    smsGet: 'Get SMS Code',
    smsSent: 'SMS sent to mobile number',
    noAccount: 'No account?',
    createAccount: 'Create a new account',
    wechatLoginTitle: 'QR code sign in',
    wechatLoginMsg:
      'Please use wechat to scan and log in | Auto scan after 3 seconds of simulation',
    wechatLoginResult: 'Scanned | Please click authorize login in the device',
    loginSuccess: 'Login success',
    logoutSuccess: 'Logout success'
  },
  issuance: {
    Route: 'Route',
    ConfirmationIssuance: 'Confirmed issuance ',
    refuseIssuance: 'refuseissuance ',
    detail: 'Certificate details',
    type: 'Application type',
    date: 'Date of issue',
    pleaseDate: 'Please enter date of issue',
    applicant: 'Corporate name',
    manufacturer: 'Manufacturer ',
    factory: 'Factory information ',
    auditor: 'Auditor ',
    pay: 'Payment status ',
    status: 'Status ',
    certificate: 'issue Certificate',
    upload: 'Certificate upload ',
    book: 'Original certificate ',
    mook: 'Draft ',
    mookView: 'Draft view ',
    acceptor: 'Acceptor',
    voc: 'VOC Certificate number ',
    vision: 'Version',
    sign: 'Sign',
    certificateType: 'Certificate type',
    certificateId: 'Certificate number',
    company: 'Company name',
    signer: 'Issuer',
    download: 'Download',
    pleaseFile: 'Please upload.PDF file',
    isCheck: 'Audit results ',
    checkBtn: 'Inform Interim Review ',
    viewBook: 'View certificate ',
    checkInfo: 'Please be sure to notify audit? ',
    nodate: 'No data yet ',
    tips: 'Note: Upload the content of the certificate, only need to upload the inside page of the certificate can be',
    reissue: 'Reissue',
    reissueInfo: 'Are you sure you want to reissue? Reissue will require 2 business steps again from operation draft, certificate issuance',
    draftLoad: 'Generating certificate, please wait ',
    certificatePreview: 'Certificate Preview',
    pealseRemark: 'Please enter remarks ',
    pealseFile: 'Please upload certificate ',
    isView: 'Please select data whose certificate status is "View" for reissue ',
    singleSelect: 'Please select a piece of data for reissue',
    importer: 'Importer ',
    exporter: 'Exporter ',
    CreatedTime: 'Created time',
    router: 'Route',
    address: 'Inspection address ',
    transport: 'Mode of transport ',
    inspector: 'Inspector',
    ICProcessor: 'IC processor ',
    iaf: 'IAF evaluator ',
    iafOver: 'IAF has reviewed',
    pteOver: 'PTE has reviewed',
    mookFile: 'Draft file ',
    modifyBook: 'Modify Certificate ',
    reason: 'Reject Reason',
    signRecord: 'Certificate issuing record ',
    refreshBook: 'Certificate refresh ',
    textExport: 'TEXT export ',
    Description: 'Description ',
    pleaseDescription: 'Please enter a description ',
    modifyType: 'Modify type ',
    rawData: 'Raw data ',
    newData: 'New data',
    modifyCertFile: 'Modify invoice attachment upload ',
    updateCertFile: 'Update invoice attachment upload ',
    updateBook: 'Update Certificate',
    Overtime: 'Over',
    Notissuance: 'hours has not been issued',
    Notyetissued: 'Not yet issued',
    SorCert: 'SOR/SOL certificate number',
    success: 'Success',
    checkOne: 'Please select a piece of data for certificate refresh',
    pushitem: 'Are you sure you want to push the certificate information again? Please confirm if the relevant information has been supplemented first',
    pusherror: 'Business Exception',
    changCert: 'Please select a piece of data for certificate modification',
    UpdateCert: 'Please select a piece of data for certificate update',
    method: 'Method',
    certfailreason: 'Failure Reason',
    days: 'days ',
    pushStatus: 'Push Status',
    successstatus: 'Successful',
    failstatus: 'Failed',
    signTime: 'Time of Issue',
    waitCCT: 'CCT to be signed',
    noPayInfo: 'This customer has not paid in full,please contact Finance;',
    noIDInfo: 'This customer does not have an assigned ID,please contact the person in charge of financial account management (Zhang Shishu, Xie Jie)!',
    customerBlack: 'This customer is on the blacklist (permanently blacklisted)!',
    customerBlackSuspend: 'This customer is on the blacklist (suspended)!',
    noCheckMonthlyReview: "This customer's monthly batch settlement review is pending preliminary review, please contact finance to deal with it in a timely manner!",
    noMonthlyReview: 'This customer failed to pass the preliminary review of the monthly batch settlement, please contact the finance to deal with it in a timely manner!',
    noReviewCheckMonthlyReview: "This customer's monthly batch settlement review is pending review, please contact finance to deal with it in a timely manner!",
    noReviewMonthlyReview: 'This customer failed to pass the review of the monthly batch settlement, please contact finance to deal with it in time!',
    noCheckSettlementApproval: "The approval of this customer's batch settlement method is pending review, please contact finance to deal with it in a timely manner!",
    noSettlementApproval: "The approval of this customer's batch settlement method has not been approved, please contact finance to deal with it in time!",
    noManagerSettlementApproval: "The approval of this customer's batch settlement method is subject to the approval of the financial manager, please contact the finance department to deal with it in time!",
    updateDate: 'Update Date',
    productError: 'There may be a problem with the certificate that this customer can provide, please check in the product information!'

  },
  certificateIssuance: {
    type: 'Application type',
    Route: 'Route',
    company: 'Company name',
    importer: 'Importer',
    exporter: 'Exporter',
    transport: 'Mode of transport',
    ICProcessor: 'IC processor',
    inspector: 'Inspector',
    iaf: 'IAF/PTD',
    InspectionTimes: 'Factory inspection time',
    status: 'Status',
    auditStatus: 'Financial status',
    issueDate: 'Date of issue',
    NumberofLine: 'Number of Line',
    mookFile: 'Draft file',
    book: 'Original certificate'
  },
  certificateManagement: {
    idf: 'IDF No.',
    ucr: 'UCR No.',
    coc: 'Certifate No.',
    issueDate: 'Date of issue',
    InspectionDate: 'Inspection Date',
    company: 'Company name',
    importer: 'Importer',
    exporter: 'Exporter',
    Route: 'Route',
    transport: 'Mode of transport',
    ICProcessor: 'IC processor',
    NumberofLine: 'Number of Line',
    ExportCopy: 'Export Copy',
    book: 'Original certificate',
    KEBSApiSend: 'KEBS Api Send',
    issueStart: 'Issue Start',
    issueEnd: 'Issue End'
  },
  basicInformation: {
    cancelOrder: 'Cancel order',
    suspendOrder: 'Suspend order',
    rfcNo: 'RFC No.',
    applyType: 'Application type',
    applicant: 'Applicant',
    factory: 'Factory information',
    product: 'Product name',
    createdTime: 'Created time',
    status: 'Status',
    acceptance: 'Audit acceptance',
    adjustProcess: 'Document adjustment process',
    suspendReason: 'Pause reason',
    cancelReason: 'Reason for cancellation',
    serverType: 'Service type',
    returnReason: 'Reason for return',
    sureCancel: 'Are you sure to cancel the RFC NO.: ',
    sureSuspend: 'Are you sure to suspend the RFC NO.: ',
    sureMessage: ' order?',
    pleaseSelectCancel: 'Please select a data before cancelling the order!',
    pleaseSelectSuspend: 'Please select a data to pause the order!',
    pleaseSelectAdjustment: 'Please select a piece of data for the document transfer process operation!',
    pleaseSelectRecheck: 'Please select a piece of data to retest!',
    pleaseSelectColunm: 'Please select a piece of data before operation!',
    cancelSuccess: 'This order has been cancelled',
    suspendSuccess: 'This order has been suspended',
    accept: 'Accept',
    sendBack: 'Return',
    acceptTip:
      'Tip: Acceptance/return will send a notification to the customer, the reason for return, will be shown to the customer!',
    // 受理
    pleaseEnterSendRemarks: 'Please enter the reason for return',
    pleaseSelectStatus: 'Please select Audit acceptance status',
    // 调流程
    pleaseEnterRemarks: 'Please enter remarks',
    pleaseSelectOrderStatus: 'Please select order status',
    // 标记优先
    flagPriority: 'Flag priority',
    flagPriorityTip: 'Mark priority, order color changed to green, remind the business attention, determine the mark option?',
    productTip: '* The product details are displayed in separate pages, please edit and save them in separate pages',
    updateTime: 'Update time',
    exportWarning: 'You can\'t export them all, you can select one, multiple, or filter by date, etc. before exporting!'
  },
  applicationForm: {
    placeOrdersOnline: 'Place orders online and manage orders quickly',
    draftsDetailTitle: 'Details of Draft Application',
    statusDetailTitle: 'Details of application processing',
    businessGuide: 'Business Application Guide',
    importantNote:
      'Important note: This form must be completed and submitted to WSCT prior to shipment',
    FillOutTheFormOnlineHint:
      'Fill out the form online or call the historical data or download the template to fill in and then submit',
    country: 'Country Destination',
    certificateType: 'Certificate Type',
    choose: 'Choose',
    createType: 'Reference information',
    selectFormTypeTitle: 'Reference information',
    applicationFormTitle: 'Basic information',
    applicationFormTitle2: 'Basic information',
    uploadTitle:
      'Upload  Attachment and the proforma invoice of the inspection application form',
    downloadTemplate: 'Download Template',
    import: 'Import',
    saveDraft: 'Save as draft',
    pleaseChoose: 'Please click the button to make a selection',
    createMode: 'Create Mode',
    createForm: 'create Form',
    importApplicationData: 'Example Import historical application data',
    uploadText: 'Click Or drag file here ',
    upload2: 'upload',
    uploadTip:
      'jpg/gif/png/bmp/doc/docx/xlsx/xls/pdf/txt/zip/tar files with a size less than 30MB',
    uploadDocument: 'Upload transaction document',
    documentText: 'Transaction document',
    documentTip: 'Packing List  / IDF Document / Quality Document / Others',
    documentMessage01:
      '1、The size of each uploaded attachment should not exceed 100M; otherwise, it cannot be submitted normally.',
    documentMessage02:
      '2、The supported file types are jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、tar.',
    fileType: 'File type',
    fileName: 'File name',
    size: 'Size',
    remarks: 'Remarks',
    companyName: 'Company name',
    companyNameEn: 'Company name(EN)',
    city: 'City',
    address: 'Address',
    zipCode: 'Zip code',
    contactName: 'Contact name',
    telephone: 'Telephone',
    telephonePlaceholder: 'Example: + 86-10-88888888 or 86-010888888',
    email: 'Email',
    fax: 'Fax',
    chooseHistoricalData: 'Choose historical data',
    currentApplicationCannotEdit:
      'The status of the current certification application cannot be edited, please contact the salesman for operation',
    timeCannotEarlier: 'The time cannot be earlier than the current date',
    timeCannotToday: 'The time cannot exceed the current date',
    pleaseImproveProduct: 'Please improve the product information:',
    limitFilesNum: 'Upload a maximum of 5 attachments at a time',
    sureSaveDraft: 'Are you sure to save the draft?',
    uploadSuccess: 'Upload successful',
    uploadError: 'Upload failed',
    toModify: 'To modify',
    haveFailDocument:
      ' attachments are being uploaded or uploading failed. Are you sure to submit?',
    reupload: 'Reupload',
    excelImport: 'Import Excel',
    improvedocumentInfo: 'Please enter the required attachment information',
    upload20Attachments: 'Tips: Upload up to 20 attachments!',
    selectHistoryData: 'Please select historical application data',
    selectApplyType: 'Please select Application For',
    pleaseUpdateTemplate: 'Please import an excel template',
    whetherInspect: 'Whether to inspect goods remotely',
    saveToDraft: 'save to draft',
    oneClickTop: 'one click top',
    // 附件信息
    selectFileType: 'Please select a file type',
    pleaseUploadFile: 'Please upload the attachment',
    // 详细信息
    inputCompanyName: 'Please enter company name',
    inputCompanyNameEn: 'Please enter company name(English)',
    validateZipCode: 'Please input "Postcode"',
    validateZipCodeFormat: 'Please enter a 4-6 number postcode',
    countryName: 'Please enter country',
    cityName: 'Please enter city',
    inputAddress: 'Please enter address',
    inputContact: 'Please enter contact name',
    inputTelephone: 'Please enter telephone',
    inputEmail: 'Please enter email',
    inputEmailReg: 'Please enter the correct email format',
    inputTelephoneReg:
      'The value is in the format of 4 to 20 digits and special characters +-',
    // 加载
    loading: 'Loading...',
    submitting: 'Submitting...',
    uploading: 'Uploading...',
    // 用户条款
    userTerms: 'User terms',
    companyAddress: ', The address is ',
    ourCompany: 'Our company, ',
    userTermsStated:
      ', It is hereby stated that the declared products are not affected by the recalled products, nor are they unqualified or fake or shoddy products. We take responsibility for the authenticity of all declared information. At the same time, we are fully aware of the regulatory requirements of importing countries on the import of these products and are committed to complying with these requirements.',
    userTermsInfo:
      'After submitting the information, WSCT will accept the application form and contact you via email as soon as possible. Please confirm whether you can be reached by this email address ',
    usreTermsInfo2:
      '. If not, please fill in below, multiple email address with ";" Separate.',
    // 认证
    applicationDonotEdit:
      'The status of the current certification application cannot be edited, please contact the salesman for operation.',
    addApplicationSaveSuccess: 'Automatic saving succeeded',
    addApplicationSaveError: 'Failed to save automatically',
    addApplicationSubmitSuccess: 'Submit successfully',
    addApplicationSubmitError: 'Submit failure',
    addApplicationDraftsSuccess: 'Saved draft successfully',
    addApplicationDraftsError: 'Save to draft failed',
    completeApplicationContent:
      'Please complete the required contents of the certification application',
    pleaseSelectCustomer: 'Please select the applicant',
    pleaseEnterKeyword: 'Please enter the company name keyword search and select',
    pleaseSelectKeyword: 'Please select the applicant',
    // 调流程
    orderStatus: 'Order status',
    // 历史数据
    selectHistory: 'Select historical application data',
    invoiceInfo: 'Invoice Information (final invoice or proforma invoice)',
    // 暂停订单
    recover: 'Transfer order',
    currentStatus: 'Current state',
    beforeSuspendStatus: 'Status before the pause',
    restoreTip1: 'Are you sure to restore order ',
    restoreTip2: ' to "',
    restoreTip3: '" status?',
    // 附件
    oneclickDownload: 'One-click download',
    recoverFile: 'Recover file',
    addFile: 'Add file',
    whetherToAdopt: 'Whether to adopt',
    publicOrNot: 'Public or not',
    // 委托申请人
    accountNumber: 'Account number',
    // 附件
    pleaseSelectCreateTime: 'Please select time',
    titleitem: 'You give your application a name so that you can search and distinguish between different applications, and the application titles of the two applications cannot be duplicated.',
    titleitemone: 'You can name it in a way that is convenient for your own statistics and query: 1) Company name abbreviation - product abbreviation - number; 2) Trademark - Date - Number.',
    // 当地商业登记号
    localBusinessRegistrationNumber: 'Local commercial registration No.',
    // 进口商/收货人是伊拉克政府部门或伊拉克相关行政部门
    importersDepartments: 'Importer/consignee is an Iraq ministry or related Iraq administration/authority',
    // 装运和检验信息：
    transportformation: 'Shipment & inspection information',
    // 交货类型
    deliveryType: 'Type of delivery',
    transportModeone: 'Transport mode',
    // 装运方式
    loadingType: 'Shipment Method',
    // 供应国
    supplyCountry: 'Country Of Supply',
    // 预计装运日期
    estimatedLoadingDate: 'Expected date of shipment',
    // 货物供应日期
    goodsSupplyDate: 'Date of availability of goods',
    // 装运方式和进入伊拉克中部的地点（必须填写进入地点信息）：
    loadingLocation: 'Shipment Method and Points of Entry to Central Iraq (lnformation on Points of Entry ls Mandatory)',
    // 以下入口点位于伊拉克中部
    belowEntryPoints: 'The following points of entry are located in Central Iraq',
    note: 'Note: Goods shall be quickly delivered after issuance of the COC. Expiny dates (if any), remaining shelf life and validity of test reports (when required) shall be in compliance with applicable standards at the date of arrival (refer to appicable standards and "VOC Iraq - Notice to Exporters" for further details)',
    // 包装数量及单位
    packingQuantityAndUnit: 'No. and type of packages:',
    // 包装数量
    packingQuantity: 'Number',
    // 包装单位
    packingUnit: 'Type of packages',
    // 附件保存成功
    attachmentsSaved: 'The attachment was saved successfully',
    // 订单类型更改， 请去重新报价。
    changeItem: 'Order type change, please go to Requote.',
    // Letter of Credit
    letterOfCredit: 'Letter of Credit',
    // Bill of Lading
    billOfLading: 'Bill of Lading'
  },
  // 验货相关字段
  inspectionData: {
    route: 'Route',
    importer: 'Importer',
    importerPlaceholder: 'Please input a Importer',
    exporter: 'Exporter',
    exporterPlaceholder: 'Please input a Exporter',
    placeOfInspection: 'Place of inspection',
    applicant: 'Applicant',
    hasIDFNumber: 'Has IDF Number',
    IDFNo: 'IDF No.',
    UcrNo: 'UCR No.',
    mailCertificateCollection: 'Mail Certificate Collection',
    shipmentMethod: 'Shipment Method',
    cargoType: 'Cargo Type',
    containerInfo: 'Container Info',
    placeOfLoading: 'Place&Port Of Loading',
    placeOfDischarge: 'Place&Port Of Discharge',
    countryOfSupply: 'Country Of Supply',
    countryOfOrigin: 'Country Of Origin',
    quantityDelivered: 'Quantity Delivered',
    orderStatus: 'Order status',
    companyName: 'Company name',
    updateTime: 'Update time',
    cargoDescription: 'Cargo description',
    standardNumber: 'Standard number',
    invoiceNo: 'Invoice No.',
    customsCode: 'Customs code',
    dllNo: 'DLL No.',
    importerPin: 'Importer Pin',
    exporterPin: 'Exporter Pin',
    beneficiary: 'Beneficiary',
    creatorName: 'Creator name',
    submitTime: 'Submission time',
    createdTime: 'Created time',
    applyType: 'Application type',
    productRiskOptions: 'Product risk options',
    inspectionAddressRiskOptions: 'Inspection address risk option',
    financeAuditStatus: 'Financial review status',
    isDispatch: 'Whether dispatch',
    dispatched: 'Dispatched',
    IAFview: 'IAF Results',
    inspectionDispatchViewing: 'Inspection dispatch viewing',
    notDispatched: 'Undispatched',
    source: 'Source',
    customer: 'Customer',
    backstage: 'Backstage',
    manufacturer: 'Manufacturer',
    factory: 'Factory'
  },
  productConformity: {
    placeOrdersOnline: 'Place orders online and manage orders quickly',
    draftsDetailTitle: 'Details of Draft Application',
    statusDetailTitle: 'Details of application processing',
    businessGuide: 'Business Application Guide',
    importantNote: 'Important note: This form must be completed and submitted to WSCT prior to shipment',
    FillOutTheFormOnlineHint: 'Fill out the form online or call the historical data or download the template to fill in and then submit',
    basicContentsForm: 'Fill in the basic content of inspection application form',
    uploadAttachmentAndInvoice: 'Upload Attachment and the proforma invoice of the inspection application form',
    uploadAttachmentAndInvoice2: 'Attachment and the proforma invoice of the inspection application form',
    uploadAttachment: 'Upload the attachment required for inspection application form',
    uploadAttachment2: 'The attachment required for inspection application form',
    pleaseEnterParties: 'Please enter the details of the parties',
    pleaseEnterParties2: ' The details of the parties',
    finalInvoice: 'Proforma invoice',
    itemsSending: "Please add the items you're sending (At least one item is mandatory)",
    itemsSending2: "The items you're sending (At least one item is mandatory)",
    createType: 'Reference information',
    selectFormTypeTitle: 'Reference information',
    applicationFormTitle: 'Fill in the basic content of inspection application form',
    applicationFormTitle2: 'The basic content of inspection application form',
    uploadTitle: 'Upload  Attachment and the proforma invoice of the inspection application form',
    downloadTemplate: 'Download Template',
    import: 'Import',
    saveDraft: 'Save as draft',
    addInvoice: 'Add Invoice',
    invoiceText: 'Invoice',
    invoiceRemind: 'At least one invoice information must be added',
    upload: 'Upload',
    invoiceNO: 'Invoice NO.',
    invoiceFile: 'Invoice file',
    invoiceDate: 'Invoice date',
    currency: 'Currency',
    amount: 'Amount',
    incoterm: 'Incoterm',
    uploadText: 'Click Or drag file here ',
    upload2: 'upload',
    uploadTip: 'jpg/gif/png/bmp/doc/docx/xlsx/xls/pdf/txt/zip/tar files with a size less than 30MB',
    uploadDocument: 'Upload Transaction Document',
    documentText: 'Transaction Document',
    documentTip: 'Packing List  / IDF Document / Quality Document / Others',
    documentMessage01: '1、The size of each uploaded attachment should not exceed 100M; otherwise, it cannot be submitted normally.',
    documentMessage02: '2、The supported file types are jpg、gif、png、bmp、doc、docx、xlsx、xls、pdf、txt、zip、tar.',
    fileType: 'File Type',
    fileName: 'File Name',
    size: 'Size',
    remarks: 'Remarks',
    companyName: 'Company Name',
    companyNameEn: 'Company Name(EN)',
    country: 'Country',
    city: 'City',
    address: 'Address',
    zipCode: 'Zip Code',
    contactName: 'Contact Name',
    telephone: 'Telephone',
    telephonePlaceholder: '示例：+86-10-8888888 或 86-010888888',
    email: 'Email',
    fax: 'Fax',
    payWSCTService: 'Pay for WSCT service',
    exporterDetails: 'Seller/Exporter Details',
    placeOfInspection: 'Place of Inspection',
    chooseHistoricalData: 'Choose historical data',
    importerDetails: 'Buyer/Importer Details',
    warehouseName: 'Warehouse Name',
    warehouseNameEn: 'Warehouse Name(EN)',
    zoneProvince: 'Zone/Province',
    originalRegion: 'Original region/province',
    GoodsAvailableDate: 'Goods Available For Inspection As From',
    pleaseSelectRfcType: 'Please select the Application For first',
    beneficiary: 'Beneficiary',
    addProduct: 'Add Product',
    editProduct: 'Edit Product',
    HSCode: 'HSCode',
    brand: 'Brand',
    modelNumber: 'Model Number',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    unit: 'Unit',
    goodsDescription: 'Goods Description',
    manufacturerName: 'Manufacturer Name',
    manufacturerAddress: 'Manufacturer Address',
    countryOfOrigin: 'Country Of Origin',
    goodsCondition: 'Goods Condition',
    assemblyState: 'Assembly State',
    cargoCondition: 'Cargo status',
    supervisoryDepartment: 'Supervisory department',
    ApplicableStandard: 'Applicable Standard(S) & Normative References',
    brOfRegistration: 'BR Of Registration/License/Product Cert',
    correspondingCertificate: 'Corresponding Registration Certificate (SOR)/License Certificate (SOL)/Product Certificate (PC) number',
    ClearAllItems: 'Clear all items',
    getTemplate: 'Get template',
    importItems: 'Import items',
    productList: 'Product List',
    uploadProductTip: 'The imported data file is in xlsx format and no larger than 20MB',
    containerSize: 'Container Size',
    containerQuantity: 'Container Quantity',
    applyTypeItem: 'Please select an Application For',
    getIdfNo: 'Please select Has IDF Number',
    idfNo: 'Please enter IDF No.',
    ucrNo: 'Please enter UCR No.',
    needCertPerson: 'Please select Mail Certificate Collection',
    transportMode: 'Please select Shipment Method',
    packingMethod: 'Please select Cargo Type',
    containerData: 'Please enter Container Info',
    supplierCountry: 'Please select Country Of Supply',
    selectCorrectData: 'Please select the correct ',
    registLicenseNumber: 'Registration license number',
    // 发票信息
    invoicePath: 'Please upload the invoice',
    invoiceNo: 'Please enter Invoice NO.',
    selectInvoiceDate: 'Please select Invoice Date',
    selectTradeTerm: 'Please select Incoterm',
    selectCurrency: 'Please select Currency',
    invoiceTotal: 'Please enter Amount',
    article: 'Article ',
    uploadInvoiceError: ' Failed to upload the invoice file',
    // 附件信息
    selectFileType: 'Please select a file type',
    pleaseUploadFile: 'Please upload the attachment',
    // 详细信息
    inputCompanyName: 'Please enter Company Name',
    inputCompanyNameEn: 'Please enter Company Name(English)',
    inputWarehousename: 'Please enter Warehousename',
    inputWarehousenameEn: 'Please enter Warehousename(EN)',
    countryName: 'Please enter Country',
    cityName: 'Please enter City',
    inputAddress: 'Please enter Address',
    inputContact: 'Please enter Contact Name',
    inputTelephone: 'Please enter Telephone',
    selectPostDate: 'Please select Goods Available For Inspection As From',
    inputEmail: 'Please enter Email',
    inputEmailReg: 'Please enter the correct email format',
    inputTelephoneReg: 'The value is in the format of 4 to 20 digits and special characters +-',
    inputRegionOrPrice: 'Please enter Zone/Province',
    // 产品信息
    inputHSCode: 'Please enter HSCode',
    inputBrandName: 'Please enter Brand',
    inputModel: 'Please enter Model Number',
    inputQuantity: 'Please enter Quantity',
    inputPrice: 'Please enter Unit Price',
    selectUnit: 'Please select Unit',
    inputGoodsDescription: 'Please enter Goods Description',
    inputManufacturerName: 'Please enter Manufacturer Name',
    inputManufacturerAddress: 'Please enter Manufacturer Address',
    selectOriginCountry: 'Please select Country Of Origin',
    selectFreightStatus: 'Please select Goods Condition',
    selectAssembleStatus: 'Please select Assembly State',
    inputSort: 'Please enter Sort',
    selectHistory: 'Select historical application data',
    importExcel: 'xlsx files with a size less than 20MB',
    noCountryText: 'The country information is not queried',
    applicationDonotEdit: 'The status of the current certification application cannot be edited, please contact the salesman for operation.',
    addApplicationSaveSuccess: 'Automatic saving succeeded',
    addApplicationSaveError: 'Failed to save automatically',
    addApplicationSubmitSuccess: 'Submit successfully',
    addApplicationSubmitError: 'Submit failure',
    addApplicationDraftsSuccess: 'Saved draft successfully',
    addApplicationDraftsError: 'Save to draft failed',
    completeApplicationContent: 'Please complete the required contents of the certification application',
    lessAddOneProduct: 'Please add at least one product item',
    pleaseAddInvoice: 'Please add invoice information',
    improvedocumentInfo: 'Please enter the required attachment information',
    upload20Attachments: 'Tips: Upload up to 20 attachments!',
    selectHistoryData: 'Please select historical application data',
    selectApplyType: 'Please select Application For',
    pleaseUpdateTemplate: 'Please import an excel template',
    whetherInspect: 'Whether to inspect goods remotely',
    pleaseSaveOrEdit: 'Please save or delete the attachment information in the edit state',
    maxInvoiceText: 'Upload a maximum of three invoices',
    currentApplicationCannotEdit: 'The status of the current certification application cannot be edited, please contact the salesman for operation',
    timeCannotEarlier: 'The time cannot be earlier than the current date',
    timeCannotToday: 'Cannot exceed the current date',
    pleaseImproveProduct: 'Please improve the product information:',
    limitFilesNum: 'Upload a maximum of 5 attachments at a time',
    sureSaveDraft: 'Are you sure to save the draft?',
    uploadSuccess: 'Upload successful',
    uploadError: 'Upload failed',
    toModify: 'To modify',
    haveFailDocument: ' attachments are being uploaded or uploading failed. Are you sure to submit?',
    reupload: 'Reupload',
    excelImport: 'Import Excel',
    exportProduct: 'Export product',
    productManagement: 'Product management',
    model: 'Model',
    importProduct: 'Import product',
    // 运输信息
    packagingInformation: 'Packaging Information',
    packageQuantity: 'Package Quantity',
    transportContainerNumber: 'Transport Container Number',
    acid: 'ACID',
    importersTaxNumber: "Importer's Tax Number",
    exporterID: 'IDExporter ID',
    exportCountry: 'Export Country',
    exportCountryCode: 'Export Country Code',
    limitContainerNo: 'A maximum of 10 transport container numbers can be added',
    limitContainerNoItem: 'The transport container number shall be letters and numbers and shall not exceed 20 in length',
    pleaseInputTransportNum: 'Please enter the shipping container number, press Enter to add',
    pleaseInputacid: 'Please enter ACID',
    pleaseSelectpackaging: 'Please select packaging information',
    pleaseInputPackageNum: 'Please enter the packing quantity',
    pleaseInputImportersTax: "Please enter the importer's tax number",
    pleaseInputExportID: 'Please enter the exporter ID',
    pleaseSelectExportCode: 'Please select export country code',
    inputAfterEnter: 'Type and press Enter to add',
    // 加载
    loading: 'Loading...',
    submitting: 'Submitting...',
    uploading: 'Uploading...',
    // 用户条款
    userTerms: 'User terms',
    companyAddress: ', The address is ',
    ourCompany: 'Our company, ',
    userTermsStated: ', It is hereby stated that the declared products are not affected by the recalled products, nor are they unqualified or fake or shoddy products. We take responsibility for the authenticity of all declared information. At the same time, we are fully aware of the regulatory requirements of importing countries on the import of these products and are committed to complying with these requirements.',
    userTermsInfo: 'After submitting the information, WSCT will accept the application form and contact you via email as soon as possible. Please confirm whether you can be reached by this email address ',
    usreTermsInfo2: '. If not, please fill in below, multiple email address with ";" Separate.',
    // 发票信息补充
    finalInvoiceTitle: 'Final invoice information supplement',
    invoiceNumber: 'Invoice number',
    invoiceAmount: 'Invoice amount',
    exchangeRate: 'Exchange rate',
    invoiceTime: 'Invoice date',
    grossWeight: 'Gross weight',
    noteExchangeRete: 'Note: This exchange rate is required when the currency is Euro, EUR/ Kenya Shilling',
    noteExchangeReteIQ: 'Note: This exchange rate is required when the currency is Euro, EUR/ Kenya Shilling',
    // 恢复文件
    deleteTime: 'Delete Time',
    AttachmentInfo: 'Attachment Information',
    otherInfo: 'Information of all parties',
    // 报价过的申请单修改币种后提示
    notice: 'Notice',
    changeInvoiceTip: 'Change the proforma invoice amount, currency, or official invoice amount, currency, will affect the quotation, please go to the quotation page as soon as possible!',
    changeInvoiceTipone: 'Changing the certificate type will affect the quote, please go to the quote page to requote as soon as possible!',
    productHaveWSCT: 'Are there non WSCT issued certificates available in the product list that can be cleared?',
    // 产品管理
    pleaseInputOrderId: 'Sort cannot be empty',
    // 请选择装运方式
    pleaseSelectLoadingType: 'Please select a shipping method',
    pleaseSelectTransportMode: 'Please select a transport mode',
    // 国际标准
    internationalStandard: 'International standards',
    //  国家标准
    nationalStandard: 'National standards',
    // 企业标准
    enterpriseStandard: 'Enterprise standards',
    // 客户标准
    customerStandard: 'Customer standards',
    // 个人标准
    personalStandard: 'Personal standards',
    // 其他标准
    otherStandard: 'Other standards'
  },
  communication: {
    scopeOfCertification: 'Scope of certification',
    selectTypeTip:
      'Can engage in certification business in the following product range, your selected product is (single option)',
    selectScopeTitle: 'Select certification scope',
    selectScopeText:
      'Relevant documents for applying for VOC certification for this type of product (please read carefully and operate according to relevant requirements)',
    certificateHolderTitle: '1. Apply for a certificate holder (with',
    certificateHolderTitle2: 'Required fields)',
    applicant: 'Applicant',
    manufacturer: 'Manufacturer',
    factoryInformation: 'Factory information',
    factoryName: 'Factory name',
    coApplicant: 'Copy applicant',
    coManufacturer: 'Copy manufacturer',
    contactPerson: 'Contact person',
    website: 'Website',
    tradeLicense: 'commercial record (Trade License)',
    invoiceMailingAddress: 'Proforma Invoice',
    productParameter: 'Attachment Information',
    productInfo: 'Product information',
    txtentOfTesting: 'Extent of testing',
    productName: 'Product name ',
    mainNumber: 'M/N ',
    addingModel: 'Adding model',
    tradeName: 'Trade name',
    highestOperating:
      ' the highest operatingfrequency(such as: lC, Crystal, resonance......)',
    EUTOperatingTemperature: 'EUT operating temperature range',
    EUTOperatingVoltage: 'EUT operating voltage range',
    ratedVoltage: 'Rated voltage',
    ratedPower: 'Rated power',
    ratedCurrent: 'Rated current',
    input: 'input',
    output: 'output',
    CEHardwareVersion: 'Hardware version NO(CE approval)',
    CESoftwareVersion: 'Software version NOCE approval)',
    adaptorMN: 'Adaptor M/N',
    batteryMN: 'Battery M/N',
    adaptorRatings: 'Adaptor ratings',
    batterySpecification: 'Battery specification',
    password: 'Password(approval for mobile phone, tablet PC)',
    protectionAgainst: 'Class of orotection against electric shock',
    additionalInfo: 'Additional information, please attach full details ',
    additionalInfoText1:
      '1. Click the "+" button and select Upload attachment.',
    additionalInfoText2:
      '2. The size of each uploaded attachment cannot exceed 10M, and a maximum of 5 files can be uploaded.',
    additionalInfoText3:
      '3. The supported file types are jpg, gif, png, bmp, doc, docx, xlsx, xls, pdf, txt, zip, and rar.',
    internationalScope: 'Applicable international standards',
    EUStandard: 'EU,African and Southeast Asian countries standards',
    USACanadaStandard: 'American,Canadian American standard',
    othersStandard: 'Other country specific standards',
    other: 'other',
    subcontract: 'Subcontract',
    isAcceptTest:
      "We don't accept the test from test report outside WSCT ( If not selected, means accept subcontracting)",
    handlingInstruction: 'Handling instruction of samples',
    fetchByYourself: 'Fetch by yourself',
    toBeCollected: 'To be collected',
    toBeDestroyed: 'To be destroyed confidentially by WSCT',
    destroyedReminder:
      '* Note: If no any choice is made, all samples will be destroyed after storage. If applicants choose returning the sample, but don’t get the sample back within twelve month or don’t accept to pay courier fee on COLLECT, we assume applicants give up the sample, and WSCT is in charge to destroy the sample. ',
    specialVATInvoice: 'Special VAT invoice',
    ordinaryVATInvoice: 'Ordinary VAT invoice',
    proFormaInvoice: 'Proforma invoice',
    taxpayerNumber: 'Taxpayer identification number',
    invoiceHeader: 'Invoice title (invoice name must match payment name)',
    bankCode: 'Bank code',
    bankName: 'Bank name',
    receiver: 'Receiver',
    applyingItem: 'Applying item',
    newApplication: 'New application',
    modification: 'Modification',
    specifyReport:
      'Please specify the original test report / certificate number',
    standardUpdate: 'Standard update',
    alternate: 'Alternate components',
    alternateModels: 'Alternate models',
    grandeeCode: 'Grandee code',
    productCode: 'Product code',
    burundiRpc: 'Burundi RPC',
    companyNo: 'Company No',
    CertifiedProduct: 'Certified product',
    inputAddress: 'Please enter the company address',
    inputAddressEn: 'Please enter the company address(English)',
    inputContact: 'Please enter the contact person',
    inputUrl: 'Please enter the URL',
    inputTradeLicense: 'Please enter your business license number',
    pleaseInput: 'Please enter content',
    pleaseInputCategory: 'Please select a certified product',
    pleaseInputScope: 'Please select an authentication scope',
    pleaseCompleteForm: 'Please complete the form information',
    pleaseInputCertificate: 'Please select a certificate type',
    pleaseSelectSampleHand: 'Please select sample handling method',
    pleaseSelectApplyType: 'Please select an application type',
    pleaseSelectStandard: 'Please select applicable international standards',
    upload5Attachments: 'Tip: Upload up to five attachments!',
    filesTips: 'Electronic information, such as ratings or technical parameters, can be uploaded as an electronic attachment to the application.',
    pleaseSelectInvoiceType: 'Please select the invoice type',
    viewContract: 'Check the testing and certification contract',
    pleaseInputInvoiceHeader: 'Please enter the invoice title',
    pleaseInputBankCode: 'Please enter your bank code',
    pleaseInputBankName: 'Please enter bank name',
    pleaseInputReceiver: 'Please enter receiver',
    pleaseInputTaxpayerNumber: 'Please enter taxpayer identification number'
  },
  setps: {
    auditAcceptance: 'Audit acceptance',
    quoteSignContract: 'Quote/Sign a contract',
    dispatchLaboratory: 'Dispatch laboratory',
    uploadRawTestData: 'Upload raw test data',
    uploadDraftTestData: 'Upload draft test data',
    firstInstance: 'First instance',
    reexamine: 'Reexamine',
    MakeADraft: 'Make a draft',
    certificateIssuance: 'Certificate issuance'
  },
  customer: {
    pleasUserName: 'Please enter your login name ',
    pleasCompanyName: 'Please enter company name ',
    methodCheck: 'Settlement method approval',
    join: 'join the blacklist',
    remove: 'Remove the blacklist ',
    user: 'User name ',
    keyword: 'Keyword',
    email: 'Mailbox ',
    phone: 'Mobile number ',
    company: 'Company name ',
    customer: 'Customer Type ',
    risk: 'Customer risk ',
    payMethod: 'Payment method ',
    submitTime: 'Submission time ',
    PleaseSubmitTime: 'Please select a submission time ',
    check: 'Batch settlement review ',
    waitCheck: 'To be reviewed ',
    loginName: 'Login name ',
    password: 'Password ',
    avatar: 'Customer avatar ',
    license: 'Business license ',
    name: 'Name ',
    legal: 'Corporate legal person',
    qq: 'Wechat Signal /QQ',
    country: 'Country',
    address: 'Address',
    addressDetal: 'Address details',
    period: 'Account period ',
    code: 'Zip code ',
    joinInfo: 'Adding the customer to the blacklist will affect the issuance of certificates/reports for all services applied by the customer. Are you sure to add the customer to the blacklist?',
    file: 'Protocol Upload',
    general: 'General settlement ',
    bulk: 'Bulk settlement',
    business: 'Business',
    normal: 'Single settlement',
    lot: 'Batch settlement ',
    exporter: 'Exporter ',
    manufacturer: 'Manufacturer ',
    importer: 'Importer ',
    agency: 'Agency',
    china: 'China',
    kenya: 'Kenya',
    thirty: 'Thirty days ',
    sixty: 'Sixty days',
    nity: '90 days ',
    subBusiness: 'Subclass service',
    result: 'Approval result',
    passDate: 'Pass date',
    monthCheckMethod: 'Monthly Batch Settlement Review',
    bulkFile: 'Bulk settlement agreement',
    pleaseName: 'Please enter your name ',
    pleasePhone: 'Please enter your phone number ',
    pleasePass: 'Please enter your password ',
    pleasEmail: 'Please enter an email address ',
    pleaseType: 'Please select a customer type ',
    pleasCountry: 'Please select a country ',
    pleasMethod: 'Please select payment method ',
    pleasDetail: 'Please enter address details ',
    pleasPeriod: 'Please select payment period ',
    pleaseFile: 'Please upload bulk settlement agreement ',
    pleaseImg: 'Please upload Business license',
    blackwarning: 'Select at least one piece of data to join the blacklist',
    hasBlack: 'Already on the blacklist',
    blackType: 'The type of blacklist',
    identifying: 'Customer type',
    comfiromType: 'Authentication',
    keywordsitem: 'User name/Real name/company name/mobile number',
    pleaseIdentifying: 'Please select customer identification',
    uploadFile: 'Upload the file',
    upExtension: 'Extension :.rar .zip .doc .docx .pdf .jpg .png is supported',
    blackListSuspend: 'Suspension (customers can log in to place orders, but the issuance of certificates will be suspended)',
    blackListForever: 'Permanently added to the blacklist (customers can no longer log in to the client and place orders, and the admin is also prohibited from placing orders for this customer.)'
  },
  documentAudit: {
    statementNumber: 'Statement number',
    companyName: 'Company name',
    settlementMethod: 'Settlement method',
    paymentStatus: 'Payment status',
    financeStatus: 'Financial status',
    financeAuditTime: 'Financial audit time',
    totalAmount: 'Total amount',
    currency: 'Currency',
    amountPaid: 'Amount paid',
    unpaidAmount: 'Unpaid amount',
    auditStatus: 'Financial status',
    paymentRecord: 'Payment record',
    UploadReceipt: 'Upload receipt',
    exprot: 'derive',
    deleteReceipt: 'Delete receipt',
    createStatement: 'Create a statement',
    createStatements: 'Create a statement(Bulk billing)',
    exportStatement: 'Export statement',
    version: 'Version',
    normalVersion: 'Normal version',
    itemQuantity: 'Item quantity',
    pleaseSelectVersion: 'Please select version information',
    issueDate: 'Date of issue',
    serviceStatus: 'Service status',
    rfcSubmitDate: 'RFC submission date',
    create: 'Create',
    quotationDetails: 'Quotation details',
    paymentPeriod: 'Payment period',
    pleaseSelectRow: 'Select a row of data for operation',
    deleteStatement: 'Delete receipt',
    sureDeleteStatement: 'Are you sure you want to delete data from statement number:',
    sureDeleteStatement2: '?',
    statusName: 'Business status',
    payer: 'payer',
    rfcStatus: 'RFC status',
    pleaseSelectRfc: 'Please select the application form before creating the statement',
    exportPay: 'Export quotation',
    pleaseSelectminRow: 'Select at least one piece of data for operation',
    pleaseSelectmaxRow: 'Export up to ten quotation data at a time',
    priceSheet: 'Price sheet',
    pleaseSelectApplyType: 'Please select a business type first',
    containerMsg: 'Please enter only one item of container information',
    checkBankInfo: 'Error checking bank information: ',
    creator: 'Creator'
  },
  about: {
    laboratory: 'Laboratory module',
    list: 'list',
    homepage: 'homepage',
    Refresh: 'Refresh',
    screen: 'Search',
    SerialNumber: 'Index',
    title: 'Title',
    CertificateType: 'Certificate type',
    Applicant: 'Applicant',
    manufacturer: 'Manufacturer',
    PlantInformation: 'Plant information',
    corporateName: 'Corporate name',
    RectificationRecords: 'Rectification records',
    BusinessStatus: 'Business status',
    CreationTime: 'Creation time',
    operate: 'Operate',
    ToBeDispatch: 'To Be Dispatch',
    AddLab: 'Add Lab',
    CaseNumber: 'Case number',
    DispatchObject: 'Dispatch to laboratory',
    PleaseInputDispatchDept: 'Please select the dispatch department',
    Head: 'Head',
    PleaseInputDispatchHead: 'Please select the person in charge of dispatch',
    describe: 'describe',
    PleaseInputDescribe: 'Please enter a description',
    resetting: 'resetting',
    submit: 'submit',
    ToBeUploaded: 'To be uploaded',
    uploadTestReport: 'Upload draft test report',
    reporType: 'report type',
    file: 'file',
    IsItAbnormal: 'Is It Abnormal',
    notes: 'notes',
    UploadAttachments: 'Upload Attachments',
    preliminary: 'Preliminary evaluation of the report',
    reEvaluation: 'Report Review',
    abnormalDetails: 'Exception details',
    abnormalRectification: 'Abnormal rectification',
    Dispatch: 'Dispatch',
    UploadRawTestData: 'Upload raw test data',
    preliminaryExamination: 'preliminary examination',
    PreliminaryReviewDetails: 'Preliminary review details',
    reexamine: 'reexamine',
    ReviewDetails: 'Review details',
    PreliminaryEvaluationResults: 'Preliminary evaluation results',
    pass: 'pass',
    reject: 'Reject',
    NoPass: 'No Pass',
    ReasonForFailure: 'Reason for failure',
    rawData: 'raw data',
    TestDraftReport: 'Test Draft Report',
    PassedOrNot: 'Passed or not',
    uploadFile: 'File upload',
    ReviewResults: 'Review results',
    Burundi: 'Burundi - ARCT',
    AbnormalFile: 'Abnormal file',
    AbnormalRectificationStatus: 'Abnormal rectification status',
    Rectification: 'Rectification',
    view: 'view',
    BeforeRectification: 'Documents (before rectification)',
    AfterRectification: 'Documents (after rectification)',
    ExceptionDescription: 'Exception Description',
    exportStatement: 'Export statement',
    version: 'Version',
    normalVersion: 'Normal version',
    itemQuantity: 'Item quantity',
    pleaseSelectVersion: 'Please select version information',
    issueDate: 'Date of issue',
    serviceStatus: 'Service status',
    rfcSubmitDate: 'RFC submission date',
    create: 'Create',
    quotationDetails: 'Quotation details',
    rawDatades: 'Raw Data Details',
    TestDraftReportdes: 'Draft Test Report Details',
    not: 'not have'
  },
  contractReport: {
    paymenttime: 'Time of Payment',
    companyName: 'Company Name ',
    statementNumber: 'Statement number ',
    financeStatus: 'Financial status ',
    isIssue: 'Issue or not ',
    issueDate: 'Date of issue ',
    applicant: 'Applicant ',
    orderDate: 'Order date ',
    bookDate: 'Issue date ',
    payment: 'The payer ',
    exporter: 'Exporter ',
    importer: 'Importer ',
    financialDate: 'Billing period',
    quotedPrice: 'Quote amount ',
    currencyType: 'Currency ',
    USD: 'Reconciliation USD',
    exchangeRate: 'Exchange rate ',
    UrcNo: 'Urc No.',
    IDFNo: 'IDF No.',
    bankName: 'Bank name ',
    settlementMethod: 'Settlement method',
    paymentStatus: 'Payment status ',
    statusName: 'Service status',
    noData: 'No data can be exported!',
    // 全部 ALL，
    all: 'All',
    // 常规证书
    generalCertificate: 'Regular certificate',
    // 证书已修改
    certificateModified: 'Certificate modified',
    // 证书签发状态
    certificateStatus: 'Certificate issuance status',
    identitySign: 'Direct Customer/Agent',
    searchTip: 'Please enter the search criteria before exporting!'
  },
  quotation: {
    notInvoiced: 'not invoiced',
    invoiceIssued: 'Invoice has been issued',
    haveMailed: 'Have been mailed',
    query: 'query',
    financialManagement: 'financial Management',
    list: 'list',
    applicationType: 'Application type',
    VOCcertification: 'VOC Certification',
    NBcertification: 'NB Certification',
    EntrustmentOrderStatus: 'Entrustment Status',
    PendingAcceptance: 'Pending Acceptance',
    PendingCase: 'Pending Case',
    ToBeReviewed: 'To Be Reviewed',
    PendingReview: 'Pending Review',
    DraftToBeCertified: 'Draft To Be Certified',
    importer: 'importer',
    manufacturer: 'Manufacturer',
    MANUFACTURER: 'Co factory',
    applicant: 'Applicant',
    QuotationAmount: 'Quotation Amount',
    FinancialStatus: 'Financial status',
    SettlementMethod: 'Settlement method',
    accountPeriod: 'Account period',
    status: 'Status',
    InvoicingInformation: 'Invoicing information',
    operate: 'Operate',
    quotation: 'quotation',
    view: 'view',
    edit: 'edit',
    submit: 'submit',
    TestingAndCertificationContract: 'Testing And Certification Contract',
    address: 'address',
    recipient: 'recipient',
    customerCode: 'customer Code',
    FileNumber: 'File Number',
    title2: 'Thank you very much for consulting WSCT’s business. The following is our quotation based on the information you provided.',
    title3: 'Product name/model name: TBD',
    SerialNumber: 'Serial Number',
    ServiceItems: 'Service Items',
    UnitPriceNetValue: 'Unit Price NetValue',
    taxRate: 'tax rate(%)',
    number: 'number',
    amountOfMoney: 'amount of money',
    serviceContent: 'service content',
    select: 'select',
    sample: 'sample',
    cycle: 'cycle',
    ContractAmount: 'Contract Amount:',
    NetValue: 'Net Value',
    TotalTax: 'Total amount including value-added tax:',
    TotalAmount: 'Total amount:',
    exchangeRate: 'Exchange rate: USA-RMB USD to RMB',
    exchangeRate1: 'RMB to HKD',
    AccountInformation: 'Account information',
    account: 'account',
    AccountName: 'Account Name',
    accountNumber: 'Account Number',
    bankOfDeposit: 'Beneficiary\'s Bank',
    notes: 'notes',
    notes1: 'Payment terms: Agreement customer, refer to the specific payment terms in the agreement',
    notes2: 'The above valuation only includes the cost of one test and certificate, and does not include the cost of re inspection or individual component testing that may be required. The final quotation will be based on the received samples. The customer is responsible for bank handling fees.',
    notes3: 'This quotation is valid for one month from the date of signing by both parties.',
    notes4: 'Please provide relevant technical documents, including but not limited to: a. instruction manual (French version required for GS certification) b. nameplate c. circuit diagram and PCB layout and wiring diagram d. explosion diagram (if any) e. list of safety components and copies of certificates',
    notes5: 'If there is no special requirement, reference for the influence of Measurement uncertainty on the result judgment IEC Guide 115',
    notes6: 'The service cycle is based on the sample specified in the quotation passing the test, and the completion time for the customer to submit a complete test report with relevant information.',
    notes7: 'The applicant confirms that they have obtained, read and understand the following service guidelines of WSCT (available from relevant WSCT employees):',
    notes8: 'This quotation only includes the cost of electronic reports, certificates, and paper certificates. If a paper report is required, we will charge an additional fee of CNY 1000, and the corresponding quotation will be issued accordingly. If necessary, please check "Paper copy report" on the application form.',
    notes9: 'The applicant acknowledges that WSCT’s liability for any loss, damage, or expense of any nature and howsoever arising shall not exceed the lesser of ten times the total amount paid for the specific service in question or twenty thousand US dollars (or its equivalent in local currency).',
    notes10: 'Any disputes arising from or in connection with this quotation shall be submitted to Shanghai International Arbitration Center for arbitration.',
    company: 'World Standardlzattion Certification & Testing Group (Shenzhen) Co LTD',
    footer: 'If you have any questions about the quotation, please feel free to contact us at any time. The contact information is as follows:',
    standard: 'Service Content Standard Selection',
    pleaseSelect: 'Please select',
    pleaseInput: 'Please Input',
    pleaseUpload: 'Please upload at least 1 file',
    pleaseUploads: 'Please check if all the rectification documents have been uploaded',
    back: 'Back',
    paymentstatus: 'payment status',
    Quotationamount: 'Quotation amount',
    Currency: 'Currency',
    Preview: 'Preview',
    isInvoice: 'Due to changing the invoice information or changing the certificate type, you need to re-quote, please click "submit" to update the quote!',
    noBankInformation: 'The bank information of this business is not found, please contact the financial staff to add the relevant information!',
    reQuotation: 'Re-quote',
    remark: 'Note: After the application is successful, it will wait for approval and will be notified in a message when it is approved.',
    remittanceAccount: 'Remittance Account',
    errorRate: 'Wrong exchange rate!'
  },
  message: {
    allRead: 'All Read',
    message: 'message',
    messageCenter: 'message center',
    title: 'title',
    time: 'time',
    operate: 'operate',
    isread: 'Read or not',
    yes: 'read',
    no: 'unread',
    content: 'Content'
  },
  myProfile: {
    username: 'Login Account',
    name: 'Name',
    headimg: 'Head portraits',
    phone: 'Phone Number',
    email: 'Email',
    sex: 'Sex',
    password: 'Login Password',
    subordinateDepartment: 'Department',
    currentPassword: 'Current password',
    editPassword: 'New password',
    confirmPassword: 'Confirm password',
    passwordReset: 'Password reset',
    errorPassword: '8-12 characters, Password must contain numbers, letters, and special symbols',
    samePassword: 'Password inconsistency',
    boy: 'Boy',
    girl: 'Girl',
    changeSuccess: 'Successfully modified',
    saveSuccess: 'Successfully saved',
    companyInformation: 'Company Information',
    channelType: 'Channel type',
    compounenyNum: 'License No.',
    selectCountry: 'Select Country',
    selectProvinceCityRegion: 'Province city region',
    pleprovince: 'Please enter the province',
    addressDetail: 'Address Detail',
    companywebsite: 'Company website/official website',
    companyPhone: 'Company Phone',
    componyhumantel: 'Legal mobile',
    postcode: 'Postcode',
    companysize: 'Company qualification',
    upload: 'Upload',
    uploadFormat: 'jpg/jpeg/png/pdf, maximum 20M',
    companyintro: 'Company profile',
    business: 'Business home region',
    opinion: 'Opinion',
    companymarketvalue: 'Company market value',
    companytype: 'Company type',
    share: 'Shareholding system',
    nonShare: 'Non-shareholding system',
    listed: 'Listed',
    unlisted: 'Not listed',
    plemarketvalue: 'Please enter market value',
    wan: 'ten thousand',
    pleregistercapital: 'Please enter the registered funds',
    world500: 'Global 500',
    plecompanyrank: 'Please enter company ranking',
    service: 'Customer service staff',
    customerService: 'Customer service staff selection instructions',
    check: 'Audit information',
    opinions: 'Audit opinion',
    customerIds: 'Identity',
    companyEmail: 'Company email',
    place: 'place'
  },
  myProfileInfo: {
    phone: 'Phone Number',
    password: 'Login Password',
    subordinateDepartment: 'Department',
    currentPassword: 'Current password',
    editPassword: 'New password',
    confirmPassword: 'Confirm password',
    passwordReset: 'Password reset',
    errorPassword: '8-12 characters, Password must contain numbers, letters, and special symbols',
    samePassword: 'Password inconsistency',
    boy: 'Boy',
    girl: 'Girl',
    username: 'Login name',
    headimg: 'Head portraits',
    name: 'Name',
    sex: 'Sex',
    customerIds: 'Identity',
    mobile: 'Mobile',
    email: 'Email',
    WeChatOrQQ: 'WhatsAPP',
    selectCountry: 'Country',
    selectProvinceCityRegion: 'Province city region',
    addressDetail: 'Detailed address',
    english: '(English)',
    postcode: 'Mailbox',
    companyName: 'Company name',
    businessLicense: 'Upload License',
    corporate: 'Principal',
    companyPhone: 'Company phone',
    companyFax: 'Fax',
    remark: 'Remark',
    man: 'man',
    woman: 'woman',
    Bailor: 'Bailor',
    Manufacturer: 'Manufacturer',
    Factory: 'Factory',
    Agency: 'Agency',
    editMyProfile: 'Edit my profile',
    uploadFile: 'Drop file here or click to upload',
    uploadFormat: 'jpg/jpeg/png/pdf, maximum 20M',
    imgType: 'Picture must be jpg/png/jpeg/gif format!',
    imgSize: 'Picture size can not exceed 1MB!',
    licenseType: 'file must be jpg/png/jpeg/gif/pdf format!',
    licenseLength: 'A maximum of five files can be uploaded to the business license',
    licenseSize: 'file size can not exceed 3MB!',
    validateUserName: 'Please input Login Name',
    validateUserNameLength: 'Login Name contains 2 to 16 characters',
    validateUserNameFormat: 'Login Name must be number or English',
    validateName: 'Please input name',
    validateNameLength: 'Name contains 2 to 100 characters',
    validateNameFormat: 'Name must be Chinese or English',
    validateMobile: 'Please input Mobile',
    validateMobileLength: 'Mobile contains 8 to 15 characters',
    validateMobileFormat: 'Please enter the correct mobile',
    validatePass: 'Please input the password',
    validatePassLength: 'The password must contain at least 6 characters',
    validatePassFormat: '"PassWord" must contain digits, letters, and special symbols !"#$%&\'()*+,-./:;<=>?@[]^_`{|}~',
    validateEmail: 'Please input email',
    validateEmailFormat: 'Please enter the correct email',
    validateZipCode: 'Please input zipcode',
    validateZipCodeFormat: 'Please enter a 4-6 number zipcode',
    validateSex: 'Please input sex',
    validateIdentity: 'Please input identity',
    validateCountry: 'Please select the country',
    validateProvince: 'Please select the province city district',
    validateAddress: 'Please input address detail',
    validateCompany: 'Please input company name',
    validateCompanyLegal: 'Please input principal',
    validateCorporate: 'Please input corporate',
    validateLicense: 'Please upload business license',
    completeMyInfo: 'Please improve the account information content',
    completeMyInfoTip: 'Please complete the data before applying for business',
    Codelaceholder: 'Please input a code',
    Verificationcodesent: 'Verification code sent',
    telcomfirm: 'Mobile number verification',
    emailcomfirm: 'Email verification',
    changeTel: 'Modify',
    changeEmail: 'Modify email',
    PLcomfirmWay: 'Please select the verification method',
    newTel: 'New phone number',
    telCode: 'Mobile number verification code',
    emailbox: 'Email',
    mobiletel: 'Mobile phone',
    newEmail: 'New email',
    emailcode: 'Email verification code',
    Scancodeverification: 'Scan code verification',
    hasPhoneCode: 'The verification code has been sent to your phone, please check it carefully',
    getCode: 'Get SMS Code',
    hasEmailCode: 'We have sent the verification code to your email. Please check it carefully',
    firstgetCode: 'Please obtain the verification code first',
    changeItem: 'Changing password, email, and phone number requires verification',
    PLinput: 'Enter',
    change: 'Modify',
    personmessage: 'Contact information',
    identity: 'Identity documents',
    ID: 'ID card',
    passport: 'Passport',
    IDnum: 'Identity document',
    front: 'front',
    opposite: 'opposite',
    passportimg: 'Passport homepage',
    supportsize: 'jpg/jpeg/png/pdf, maximum 20M',
    compouyinfo: 'Company information',
    compounenyNum: 'License No.',
    PLselect: 'Please select',
    PLuploadfront: 'Please upload a photo of the front of your ID card',
    PLuploadopposite: 'Please upload a photo of the back of your ID card',
    PLuploadpassportimg: 'Please upload a photo of the passport homepage',
    acountInfo: 'Account information',
    register: 'Register',
    Existingaccount: 'Existing account? Go log in',
    Login: 'Login',
    Pleasefill: 'Please fill in the channel information and save it before applying for business',
    confirmpassword: 'Confirm password',
    Available: 'Available for login',
    notnameNumber: 'The name cannot be all digits',
    PLidNumber: 'Please enter ID number number',
    regitererr: 'login has failed',
    Contact: 'Contact information',
    WechatOther: 'Wechat /WhatsApp/ Others',
    PLtel: 'Please enter the number',
    PLidNo: 'Enter the ID number',
    idpicture: 'Id photo',
    companynum: 'Company headcount',
    companywebsite: 'Company website/official website',
    companyquality: 'The number of qualified persons in the company',
    PLselecttype: 'Please select a type',
    add: 'Add',
    delete: 'Delete',
    companysize: 'Company qualification',
    // 公司简介
    companyintro: 'Company profile',
    source: 'source',
    WSCT: 'WSCT official website',
    network: 'Network',
    WSCTintro: 'Introduction of WSCT staff',
    intro: 'Enter the name of the introducer',
    other: 'Other',
    selectid: 'Please select an identification document',
    uploadid: 'Please upload your ID photo',
    inputid: 'Please enter your ID number',
    uploadidcard: 'Please upload the first page of your ID card',
    selectcertificate: 'Select a qualification type',
    selectcertificatename: 'Select a qualification name',
    inputnum: 'Please enter number of people',
    inputcompanynum: 'Please enter the total number of people in the company',
    selectcompany: 'Please select company qualification',
    inputcompanyintro: 'Please enter company profile',
    inputWSCTintro: 'Please enter the introduction employee name',
    inputWSCTintroError: 'You cannot enter a referring employee name',
    fileTypeError: 'File type error',
    fileSizeError: 'File size error',
    selectcertificateError: 'Please select a qualification certificate',
    lengthError: 'The length must be less than 50',
    introlebal: 'Company profile',
    PLsource: 'Please enter the source',
    account: 'Please enter your account number',
    realname: 'Please enter your name',
    plemail: 'Please enter your email address',
    unifiedsocial: 'Please enter the license number',
    companyname: 'Please enter the company name',
    legalperson: 'Please enter the name of the legal representative',
    fax: 'Please enter the fax number',
    address: 'Please enter the address',
    zipcode: 'Please enter your zipcode',
    companyallnum: 'Please enter the number of employees in the company',
    website: 'Please enter the website address',
    wechatqq: 'Please enter WeChat',
    idcard: 'Please enter ID number number',
    remarks: 'Please enter the remark',
    websiteError: 'Please enter the correct URL',
    registerInfo: 'Confirmation of registration information',
    person: 'people',
    // 渠道类型
    channelType: 'Channel type',
    // 输入公司关键词点击回车搜索公司
    searchCompany: 'Enter keywords to search compony',
    // 请选择渠道类型
    selectChannelType: 'Please select channel type',
    // 公司
    company: 'Company',
    // 个人
    personal: 'Personal',
    // WSCT渠道加盟
    WSCTchannel: 'WSCT Channel Join',
    // 咨询热线0755-26996145\619226996053(海外)
    consult: 'Consult hotline 0755-26996145/6192 26996053(overseas)',
    // 微信13823277946
    wechattel: 'WeChat 13823277946',
    // 非凡事业，立即启航！
    start: 'Extraordinary career, sail now!',
    // 请输入身份证件号码
    pleidcard: 'Please enter your ID number',
    selectChannelTypea: 'Please select a channel type',
    // 请输入公司电话
    plecompanyphone: 'Please enter the company phone number',
    // 请选择手机所属国家区号
    plecountry: 'Please select the country code of the mobile phone',
    // 亲输入公司联系人电话
    plecontactphone: 'Please enter the contact phone number of the company',
    // 个人资质
    personalqualification: 'Personal qualification',
    // 个人简介
    personalintro: 'Biography',
    // 个人简历
    personalresume: 'Vita',
    // 点击或拖拽上传
    clickOrDrag: 'Click or drag to upload',
    // 上传格式为doc/docx/pdf,大小不得超过10MB
    uploadFileType: 'Upload a maximum of 1 file in pdf/docx/doc format,and the size should not exceed 10MB.',
    // 请输入省市区
    pleprovince: 'Please enter the province',
    // 法人代表手机号
    componyhumantel: 'Legal mobile',
    // 上传
    upload: 'Upload',
    // 请输入验证码
    plecode: 'Please enter the verification code',
    // 请输入公司电话
    plecompanytel: 'Please enter the company phone number',
    // 请输入公司邮箱
    plecompanyemail: 'Please enter the company email address',
    // 请输入公司网址
    plecompanywebsite: 'Please enter the company website address',
    // 请上传资质图片
    pleuploadcertificate: 'Please upload the qualification certificate',
    // 请完善已选择的个人资质项
    pleequalqualification: 'Please complete the selected personal qualification item',
    // 请上传个人简历
    pleuploadresume: 'Please upload your resume',
    // 超过上传限制
    uploadlimit: 'Exceed the upload limit',
    // 预览
    preview: 'Preview',
    // 客户标识
    customer: 'Customer identity',
    // 业务归属地区
    business: 'Business home region',
    // 意见
    opinion: 'Opinion',
    // 请输入意见
    pleopinion: 'Please input comments',
    // 结算方式
    settlement: 'Settlement method',
    // 账期
    period: 'Account period',
    // 协议上传
    uploadagreement: 'Protocol upload',
    // 公司类型
    companytype: 'Company type',
    // 请输入市值
    plemarketvalue: 'Please enter market value',
    // 请输入注册资金
    pleregistercapital: 'Please enter the registered funds',
    // 请输入公司排名
    plecompanyrank: 'Please enter company ranking',
    // 世界五百强
    world500: 'Global 500',
    // 万
    wan: 'ten thousand',
    // 位
    place: 'place',
    // 客服人员
    service: 'Customer service staff',
    // 审核信息
    check: 'Audit information',
    // 审核结果
    result: 'Audit result',
    // 审核意见
    opinions: 'Audit opinion',
    // 请选择公司类型
    plecompanytype: 'Please select company type',
    // 请选择公司是否上市
    plecompanylist: 'Please select whether the company is listed',
    // 请输入公司市值
    plecompanymarketvalue: 'Please enter the company market value',
    // 请输入公司注册资金
    plecompanyregistercapital: 'Please enter the registered capital of the company',
    // 请选择审核结果
    plecheckresult: 'Please select the audit result',
    // 请输入审核意见
    plecheckopinion: 'Please enter the audit opinion',
    // 请选择客服人员
    plecheckperson: 'Please select the customer service staff',
    // 通过
    pass: 'Pass',
    // 驳回
    reject: 'Reject',
    // 股份制
    share: 'Shareholding system',
    // 非股份制
    nonShare: 'Non-shareholding system',
    // 已上市
    listed: 'Listed',
    // 未上市
    unlisted: 'Not listed',
    // 是
    yes: 'Yes',
    // 否
    no: 'No',
    // 请选择结算方式
    plesettlement: 'Please select the settlement method',
    // 请选择账期
    pleperiod: 'Please select the account period',
    // 请上传协议
    pleuploadagreement: 'Please upload the agreement',
    // 修改结算方式
    modify: 'Modify settlement method',
    settlementMethod: 'Settlement method',
    // 公司市值
    companymarketvalue: 'Company market value',
    // 专属客服选择说明
    customerService: 'Customer service staff selection instructions',
    // 未知
    unknown: 'Unknown',
    // 客户资料审核状态
    customerCheckStatus: 'Customer information audit status',
    // 3个客户资料待审核
    customerCheckStatus3: ' customer information pending review'
  },
  IAFEvaluate: {
    fileDetails: 'File Details',
    SRNo: 'SR No.',
    PCAContract: 'PCA contract',
    whetherExist: 'Whether similar products exist',
    historicalNumber: 'Historical inspection number',
    riskProfiler: 'Risk assessment',
    customerRisk: 'Customer risk',
    POIRisk: 'POI risk',
    productRisk: 'Product risk',
    riskType: 'Risk type',
    inspectionAddress: 'Inspection address',
    description: 'Description',
    detail: 'Detail',
    againstType: 'Against type',
    exporter: 'Exporter',
    productInfo: 'Product information',
    correctiveAction: 'Recommended corrective action',
    rectificationRecord: 'Rectification record',
    detailAndResults: 'Product details and evaluation results',
    applicableStandard: 'Applicable standard',
    evaluation: 'Evaluation',
    missingParam: 'Missing or failed parameters/tests',
    acceptanceLimit: 'Acceptance Limits',
    isInspect: 'Whether to inspect the goods',
    toBeTested: 'To be tested',
    expiryDate: 'Expiry date',
    risk: 'Risk',
    level: 'Level',
    riskLevel: 'Risk level',
    selectAll: 'Select all',
    import: 'Import',
    export: 'Export',
    samplingTesting: 'Sampling/Testing',
    samplingMethod: 'Sampling Method/Instruction and Testing Method Details',
    supportingDocument: 'Supporting document',
    requiredSupporting: 'Required supporting documents for the conformity assessment process',
    factoryAudit: 'Factory audit',
    inspect: 'Inspect goods',
    fileUpload: 'File upload',
    inspectionRequire: 'Inspection picture requirements',
    remarks: 'Remarks',
    IAFVersion: 'IAF Version record',
    time: 'Time',
    IAFPTEAppraiser: 'IAF/PTE Appraiser',
    operate: 'Operate',
    downloadPDF: 'Download PDF',
    seeMore: 'See more',
    selectAllStandard: 'One-click selection',
    sureSelectedStandards: 'Are you sure to select the applicable standards for all products on this page with one click?',
    clearAllStandard: 'One click clear',
    sureClearStandards: 'Are you sure to clear the applicable standards for all products on this page with one click?',
    sureSelectedReviews: 'Are you sure to select "Evaluation" for all product on this page with one click?',
    sureInputExpiryDate: 'Are you sure to fill in the "Expiry date" for all products on this page with one click?',
    sureSelectedInspection: 'Are you sure to select "Whether to inspect the goods" for all products on this page with one click?',
    sureSelectedTested: 'Are all products required to be tested for one click selection?',
    sureInput: 'Are you sure to input all products on this page for ',
    pressEnterFill: 'Press Ctrl + Enter to fill in the ',
    pressEnterFill2: ' for all products on this page',
    changeProductPage: 'Product information on this page is incomplete,switching page number/page number will lose product information. Do you want to switch?',
    saveIAFProduct: 'Save the product information on this page',
    saveIAFProductSuccess: 'The product information on this page is saved successfully',
    saveIAFProductError: 'Failed to save the product information on this page: ',
    switch: 'Switch',
    improveInformation: 'Improve information',
    inputProductId: 'If the product information on this page is not completely filled in, the product information on this page will be lost if you search again. Do you want to search?',
    // 请输入型号
    inputModel: 'Please enter a model number',
    // 请输入海关编码
    inputCustomsCode: 'Please enter the HS code'
  },
  inspection: {
    Dispatch: 'Dispatch IC',
    waitDispatch: 'To Dispatch IC',
    Dispatcher: 'Dispatch IC acceptance personnel',
    Dispatchs: 'Dispatch objects',
    confirm: 'confirm',
    cancellation: 'cancellation',
    referto: 'Recently, this customer\'s RFC acceptor reference',
    dispatchrecord: 'RFC dispatch record',
    Acceptedby: 'Accepted by',
    Acceptor: 'Acceptor',
    way: 'way',
    time: 'time',
    prompt: 'Note: The dispatch will lock the application form at the same time. After locking, the customer can no longer edit this application form on the client side',
    // details: 'Details',
    // logs: 'Logs',
    // signIC: 'Sign the IC/ pick up',
    // acceptConfirm: 'Acceptance confirmation',
    // priceSheet: 'Price sheet',
    // signInspector: 'Sign and send inspector',
    // uploadInspectionInfo: 'Upload inspection information',
    // makeDraft: 'Make a draft',
    // issueCertificate: 'Issue a certificate',
    Acceptanceconfirmation: 'Acceptance confirmation',
    Acceptedornot: 'Accepted or not',
    inspectthegoods: 'Whether to inspect the goods',
    Reasonforreturn: 'Reason for return',
    DispatchInspectioner: 'Dispatch inspectors',
    customTime: 'Customer requested inspection/factory inspection time',
    inspectTime: 'Inspection/factory inspection time',
    loseEfficacyTime: 'expiring date',
    inspectionlevel: 'inspection level',
    SupervisionIs: 'Supervision of installation or not',
    islaboratoryTesting: 'Do you need laboratory testing',
    Remoteinspection: 'Remote inspection/factory inspection',
    notes: 'notes',
    Auditinspectionreport: 'Audit inspection report',
    CabinetAndLock: 'Cabinet number and lock number',
    Cabinet: 'Cabinet number',
    lock: 'Lock number',
    locker: 'Lock people with locks',
    lockdate: 'Lock date',
    InspectionPhotos: 'Inspection photos',
    InspectionReport: 'Inspection Report',
    MergeReport: 'Merge Report',
    Inspectionresults: 'Inspection results',
    agreement: 'The inspector follows the following instructions and protocols',
    range: 'range',
    Followtheinstructions: 'Follow the instructions',
    comment: 'comment',
    AuditOperations: 'Audit Operations',
    Passed: 'Passed',
    Return: 'Return for modification',
    NotAccepted: 'Not accepted (the company’s business does not currently involve this inspection)',
    details: 'Details',
    logs: 'Logs',
    signIC: 'Sign the IC/ pick up',
    acceptConfirm: 'Acceptance confirmation',
    priceSheet: 'Price sheet',
    signInspector: 'Sign and send inspector',
    seeInspector: 'See the inspector',
    uploadInspectionInfo: 'Upload inspection information',
    makeDraft: 'Make a draft',
    issueCertificate: 'Issue a certificate',
    upload: 'Upload inspection materials',
    Tobeuploaded: 'To be uploaded',
    Tobeuploadeds: 'To be uploaded Online',
    InspectionResults: 'Inspection results',
    reserve: 'reserve',
    Fail: 'Fail',
    InspectionTime: 'Inspection time',
    difference: 'difference',
    poirisk: 'POI risk',
    index: 'index',
    Risktype: 'Risk type',
    describe: 'describe',
    detail: 'detail',
    resetting: 'resetting',
    submit: 'submit',
    operate: 'operate',
    add: 'add',
    DistanceTraveled: 'Distance traveled',
    Dateoftravel: 'Date of travel',
    ProductConditions: 'Product identification conditions',
    Placeofinspection: 'Inspection location',
    Documents: 'file',
    Fillout: 'Fill in sampling related information',
    SamplingTesting: 'Sampling/Testing',
    Inspection: 'Inspection',
    PhotographsToBeProvided: 'Photos to be provided',
    Remarks: 'speech',
    ListOfItemsToSample: 'List of items to sample',
    SampledByCC: 'Sampling by CC',
    NumberOfItems: 'Item',
    State: 'state',
    Quantity: 'Quantity',
    Unit: 'Unit(Quantity)',
    Packages: 'Packages',
    Units: 'Units(Packages)',
    Brand: 'Brand',
    ModelNumber: 'Model number',
    GoodsDescription: 'description of the goods',
    CountryOfOrigin: 'country of origin',
    UnitPrice: 'Unit Price/Currency',
    Exclude: 'Exclude/Include',
    Action: 'Action',
    Shipment: 'Shipment',
    Cargo: 'Cargo',
    ClosesCorrectly: 'Close correctly',
    ContainerNo: 'Container number/seal number',
    SealUser: 'Sealing users',
    SealDate: 'Sealing date',
    ProductsToBeSampled: 'Product to be sampled',
    TestSampleReference: 'Test/Sample Reference',
    Qualitycontrol: 'quality control',
    GlobalInspectionResults: 'Global inspection results',
    InspectionResultLeftAtPOI: 'Inspection result left POI',
    TopFour: 'Top 4 digits of cabinet number',
    Lastseven: 'Last 7 digits of cabinet number',
    yes: 'yes',
    no: 'no',
    Formalacceptance: 'Formal acceptance',
    return: 'return',
    QuantitySize: 'Number of containers * size',
    ContainerNumber: 'Container number',
    ProductsSampled: 'Products that need to be sampled',
    Inspector: 'Inspector',
    Versionrecord: 'Version record',
    continueupload: 'Continue uploading',
    rise: 'rise',
    fall: 'reduce',
    hscode: 'customs code',
    modelNumber: 'Model number',
    GoodsDescriptions: 'description of the goods',
    Unites: 'Unites',
    AssemblyStaue: 'Assembly status',
    Packagesfound: 'The type and quantity of packages found',
    PackingCondition: 'Packing Condition',
    Marking: 'Marking',
    inspectionresults: 'Inspection results',
    Details: 'details',
    GoodsCondition: 'Condition of goods',
    containernocode: 'Container Number',
    document: 'Document Collection',
    Procedure: 'Inspection Procedure',
    picture: 'picture',
    sample: 'Sampling plan',
    container: 'Container sealing',
    check: 'view',
    conditions: 'Product identification conditions',
    location: 'Inspection location',
    ProductstoSampled: 'Product to be sampled',
    topoi: 'Inspection results remain in POI',
    modeOfTransport: 'mode of transport',
    DispatchInspectionerdeils: 'Details of dispatched inspectors',
    edit: 'edit',
    downloadPDF: 'Preview/Download PDF',
    uploadedit: 'Upload inspection data editing',
    uploadeditOnline: 'Online Upload inspection data editing',
    inspectionIc: 'Dispatch IC/Collect',
    acceptance: 'Acceptance confirmation',
    inspectionor: 'Dispatch inspectors',
    AuditReport: 'Audit Report',
    CertificateType: 'Certificate type',
    RfcNO: 'RFC NO .',
    type: 'Application type',
    Route: 'Route',
    importer: 'Importer',
    exporter: 'Exporter',
    placeOfInspection: 'place of survey ',
    Customertype: 'Company Name',
    icAccepter: 'ICProcessing officer',
    IAFEvaluator: 'IAF/PTD',
    InspectionEndTime: 'Inspection end time',
    orderStatus: 'Order status',
    updateTime: 'Update time',
    inspectionTime: 'Factory inspection report issue time',
    inspectionsEndTime: 'Factory inspection report due time',
    IAFevaluateResult: 'IAF evaluate result',
    inspectionDispatchViewing: 'Inspection dispatch viewing',
    inspectionResultViewing: 'Inspection result viewing',
    createdTime: 'createdTime',
    customerIdentity: 'customerIdentity',
    IAFview: 'IAF Results',
    isIspect: 'Inspection or not ',
    recheck: 'recheck',
    recheckReason: 'recheckReason',
    pleaserecheckReason: 'Please enter a reason for retesting',
    OfflineEdit: 'OfflineEdit',
    OnlineEdit: 'OnlineEdit',
    // 无法将
    cannotbeconverted: 'It is not possible to convert',
    // 转换为数字
    toNumber: 'to a number',
    // 被签派
    isDispatchtext: 'Dispatched',
    // 领取
    isDispatch: 'Receive',
    // 请输入六位
    sixPlease: 'Please enter six digits',
    // 请完善表格
    pleasePerfect: 'Please complete the form'
  },
  internalControl: {
    details: 'Details',
    logs: 'Logs',
    signInternalControl: 'Sign and assign internal control',
    IAFEvaluation: 'IAF Initial evaluation',
    PTEEvaluation: 'PTE Comprehensive evaluation',
    PTDAudit: 'PTD Examine and approve',
    makeDraft: 'Make a draft',
    // PTD审核
    reevaluationResult: 'Approval results',
    approvalOpinion: 'Approval opinion',
    reviewRecord: 'Approval record',
    icAccepterElevator: 'IAF evaluator',
    time: 'Time',
    route: 'Route',
    importer: 'importer ',
    importerPlaceholder: 'Please enter importer ',
    exporter: 'Exporter ',
    exporterPlaceholder: 'Exporter please ',
    applicant: 'applicant ',
    placeOfInspection: 'Inspection address ',
    isIspect: 'Is inspection',
    inspector: 'Inspector',
    icAccepter: 'IC acceptor ',
    IAFEvaluator: 'IAF evaluator ',
    PTEEvaluat: 'PTE evaluat ',
    signControl: 'send internal control ',
    IAFResult: 'IAF result ',
    modifyCCAudit: 'Modify certificate audit',
    shipmentMethod: 'Mode of transportation ',
    orderStatus: 'Status',
    approvalProposal: 'Approval results',
    argument: 'Argument',
    pleaseSelectStatus: 'Please select the reevaluation result',
    pleaseSelectRecommended: 'Please select an approval results',
    pleaseSelectRationale: 'Please select a reason',
    pleaseInputRemark: 'Please enter approval opinion',
    ptdReviewMoreThan1: 'No draft has been produced for more than ',
    ptdReviewMoreThan2: ' hours after PTD review',
    warn: 'Warn',
    searchProductTip: 'Enter the ID to query the product:39001,39002',
    factoryEndDate: 'Validity period of factory inspection report',
    UpdateTime: 'Update Time',
    IAFview: 'IAF Results',
    applyType: 'Application type',
    remark: 'Remark',
    companyName: 'Company name',
    // 创建时间
    createTime: 'Creation time',
    // 验厂报告有效期
    inspectionEndTime: 'Validity period of the factory inspection report',
    // PTE评估人
    pteEvaluator: 'PTE Assessor',
    // PTE评估结果
    pteEvaluationResult: 'PTE assessment results',
    // PTD评估人
    ptdEvaluator: 'PTD Evaluator',
    // PTD评估结果
    ptdEvaluationResult: 'PTD assessment results',
    // 复评结果
    reEvaluationResult: 'Re-evaluation result',
    mookFile: 'Draft file ',
    internalControl: 'internalControl',
    createdTime: 'createdTime',
    // 适用标准
    standard: 'Applicable Standards',
    // 请上传.xls,.xlsx文件
    pleaseUpload: 'Please upload .xls and .xlsx documents'
  },
  PTEEvaluate: {
    customerAdd: 'Customer Risk Added ',
    customerEdit: 'Customer Risk Modification ',
    productAdd: 'Product risk added ',
    productEdit: 'Product Risk Modification ',
    title: 'Title ',
    applicant: 'applicant ',
    orderStatus: 'Order status ',
    HistoricalDocuments: 'historical documents ',
    HistoricalDocumentsError: 'please select in the table a data',
    pleaseRiskType: 'Please select risk type ',
    pleaseAgainstType: 'Please enter for type ',
    listDocumenmt: 'List of documents ',
    attachType: 'attachment type ',
    fileName: 'File name ',
    uploadDate: 'Upload date ',
    signDate: 'Document issue date ',
    select: 'select ',
    isAccept: 'Whether to accept ',
    isOpen: 'Public or not ',
    fileQualityEvaluate: 'Evaluation of quality files ',
    SamplingCondition: 'Sampling condition ',
    testCondition: 'Test condition ',
    testNumber: 'Test report number ',
    testReportAgency: 'Test report issuing agency ',
    testReportDate: 'Test report issue date ',
    labAgency: 'Laboratory Authorization Agency ',
    testAgencyType: 'Test organization type',
    supplyFileInfo: 'Add quality file information ',
    otherReport: 'Other test report ',
    QMSCerificate: 'QMS Certificate ',
    QMSCerificateWay: 'QMS Certificate Verification Way ',
    iso: 'ISO17025 Qualification',
    tardeFile: 'Transaction file ',
    InspectionQuality: 'Inspection quality ',
    ScopeCertification: 'Authentication scope ',
    riskEvaluate: 'Risk evaluate ',
    riskType: 'Risk type ',
    riskSource: 'Sources of risk ',
    customerRisk: 'Customer Risk ',
    riskDescription: 'Risk description ',
    riskDetail: 'Risk detail ',
    riskRFC: 'Risk Correlation RFC',
    importer: 'importer ',
    manufacturer: 'Manufacturer',
    file: 'Attachment ',
    remarks: 'Remarks ',
    againstType: 'against type ',
    exporter: 'Exporter ',
    inspestAddress: 'Inspection address ',
    goodsRisk: 'Product Risk ',
    goodsInfo: 'Product information ',
    adivice: 'Recommended corrective action ',
    modifyRecord: 'Modify record ',
    POIRisk: 'POI Risk ',
    code: 'Customs code ',
    riskLevel: 'Risk level ',
    evaluateResult: 'Evaluation results ',
    differ: 'discrepancy',
    differModify: 'Difference rectification',
    resultDetail: 'Result detail',
    loseDate: 'Inspection report expiration date ',
    yes: 'Yes ',
    no: 'no ',
    // Customs code popup related
    brand: 'brand ',
    modle: 'Model ',
    number: 'quantity ',
    unit: 'unit ',
    singlePrice: 'unit price ',
    currency: 'money ',
    currencyDescrib: 'Currency Description ',
    manufacturerName: 'Manufacturer name ',
    origin: 'Country of origin ',
    CargoCondition: 'Condition of goods',
    AssembledState: 'Assembled state',
    validOrNot: 'Effective',
    testingScopeLaboratory: 'Testing within the scope of laboratory qualifications',
    historicalFactory: 'Historical factory inspection data',
    factoryResult: 'Factory inspection result',
    inspectionTime: 'Inspection time',
    factoryInspectionData: 'Factory inspection data',
    validityReport: 'Validity of factory inspection report',
    operator: 'Operator',
    have: 'have',
    nothing: 'nothing',
    sureCheck: 'Confirm to fill all documents with one click ',
    productDescription: 'Product Description',
    rectificationMeasures: 'Recommended rectification measures',
    PLhscode: 'Please select a customs code',
    PLrisklevel: 'Please select the risk level',
    advice: 'Recommended rectification measures',
    assessPerson: 'Evaluators',
    assessDate: 'Date of Assessment',
    ApprovalResult: 'Approval Result',
    Reevaluation: 'The Results of the re-evaluation',
    ApprovalOpinions: 'Approval Opinions',
    Approver: 'Approver',
    ApprovalTime: 'Approval Time',
    ApproveProposals: 'Approve Proposals',
    Reason: 'Reason',
    cancelDelCustomer: 'Confirm the deletion of this high-risk customer? Once deleted, it cannot be recovered!',
    ApprovalStatus: 'Approval Status',
    BelongingOrder: 'Belonging Order',
    approval: 'Approval',
    customerName: 'Customer',
    downLoadPdf: 'Download Report',
    approvalStatus: 'Approval Status',
    noRiskReport: 'There are no risk reports at this time!',
    phone: 'Phone'
  },
  modifyCCAudit: {
    title: 'Modify Certificate CC Audit ',
    modifyAudit: 'Modify Certificate audit ',
    modifyDate: 'Modify data ',
    modifyType: 'Modify type ',
    rawDate: 'Raw data ',
    newDate: 'New data ',
    file: 'Attachment ',
    reason: 'Reason',
    remarks: 'Audit remarks',
    pleaseSelectStatus: 'Please select Audit status',
    pleaseInputRemarks: 'Please enter Audit remarks'
  },
  signControl: {
    evaluateType: 'IAF,PTE evaluation type ',
    pleaseEvaluateType: 'Please select IAF,PTE evaluation type ',
    evaluator: 'IAF,PTE evaluator ',
    pleaseEvaluator: 'Please select IAF,PTE Evaluator ',
    PTDcheckType: 'PTD audit type ',
    pleasePTDcheckType: 'Please select PTD audit type ',
    PTDchecker: 'PTD Auditor ',
    pleasePTDchecker: 'Please select a PTD reviewer ',
    remarks: 'Remarks ',
    RFC: 'RFC',
    date: 'date ',
    historyDate: 'History this customer order dispatch reference data ',
    RFCRecorde: 'RFC dispatch internal control record ',
    CCT: 'CCT',
    TCT: 'TCT'
  },
  COCReport: {
    createdAt: 'Created At',
    certDate: 'Certificate Date',
    exportName: 'Exporter Name',
    exportCountry: 'Exporter Country',
    importName: 'Importer Name',
    contract: 'Contract',
    number: 'UCR/GUCE/SABER Number',
    SIRequired: 'Stated Intervention Required',
    firstIAF: 'First IAF By',
    lastIAF: 'Last IAF By',
    iLevel: 'Importer Service Level',
    applicant: 'Applicant',
    eLevel: 'Exporter Service Level',
    ship: 'Shipment Method',
    cargoType: 'Cargo Type',
    countrySupply: 'Country Of Supply',
    countryOrigin: 'Country Of Origin',
    Inspector: 'Inspector',
    POICity: 'POI City',
    loadPlace: 'Loading Place',
    dischPlace: 'Discharge Place',
    PhysicalPSI: 'Whether it is physical PSI',
    RemoteInspection: 'Remote Inspection',
    POICountry: 'POI Country',
    PCARef: 'PCA SOR/SOL Ref',
    description: 'Goods Description',
    PCARoute: 'PCA Route',
    PCATest: 'PCA Testing By',
    Certificate: 'Certificate #',
    CertificateType: 'Certificate Type',
    CertificateResultDetails: 'Certificate Category',
    CertificateStatus: 'Certificate Result Details',
    CertificateDate: 'Certificate Status',
    CertificateStatusDate: 'Certificate Status Date',
    CertifIssued: 'Certif. Issued By Country',
    CertifFirstOffice: 'Certif.First Issued By Office',
    CertifLastOffice: 'Certif. Last Issued By Office',
    NumberOfLines: 'Number Of Lines',
    FoundFOBValue: 'Found FOB Value (Ref)',
    certFirstIssuedDate: 'Certificate First Issued Date',
    certLastIssuedDate: 'Certificate Last Issued Date',
    CertificateFirstIssuedBy: 'Certificate First Issued By',
    ReIssuedLater: 'Re-Issued Later (Y/N)',
    SalesOffice: 'Sales Office',
    IC: 'IC',
    ScopeCertification: 'Scope Of Certification',
    CreatedThrough: 'Created Through Online Portal?',
    RFCCreateAt: 'Rfc Created Date',
    index: 'index',
    rfc: 'RFC#',
    idfno: 'IDF No.',
    CC: 'CC',
    TimeDifference: 'The time difference between Fosterive and Fosterk'
  },
  SQRSOLReport: {
    exportName: 'Exporter Name',
    StatementType: 'Statement Type',
    Statement: 'Statement #',
    FirstIssuedDate: 'First Issued Date',
    LastIssuedDate: 'Last Issued Date',
    exportCountry: 'Exporter Country',
    importName: 'Exporter Name',
    RFC: 'RFC #',
    Beneficiary: 'Beneficiary',
    BeneficiaryCountry: 'Beneficiary Country',
    ICOffice: 'IC Office',
    IC: 'IC',
    StatementCreationDate: 'Statement Creation Date',
    StatementIssuedDate: 'Statement Issued Date',
    StatementLastIssuedDate: 'Statement Last Issued Date',
    IssuedBy: 'Statement Issued By',
    OrderConfirmedBy: 'Order Confirmed By',
    StatementStatusDate: 'Statement Status Date',
    ValidForContract: 'Valid For Contract',
    NbProductIncluded: 'Nb Product Included',
    MinDate: 'Min Expiry Date',
    MaxDate: 'Max Expiry Date',
    Expired: 'Expired',
    LastFactoryDate: 'Last Factory Audit Date',
    remarks: 'Remarks',
    createdAt: 'Created At',
    index: 'index',
    statement: 'Statement #'
  },
  certificateItem: {
    FirstIssuedDate: 'First Issued Date',
    LastIssuedDate: 'Last Issued Date',
    createdAt: 'Created At',
    certDate: 'Certificate Date',
    exportName: 'Exporter Name',
    exportCountry: 'Exporter Country',
    importName: 'Importer Name',
    contract: 'Contract',
    number: 'UCR/GUCE/SABER Number',
    Manufacturer: 'Manufacturer',
    CertificateNb: 'Certificate Nb',
    ItemPosition: 'Item Position',
    countrySupply: 'Country Of Supply',
    countryOrigin: 'Country Of Origin',
    POICountry: 'POI Country',
    DeclaredHSCode: 'Declared HS Code',
    FourDeclaredHSCode: '4 Digits HS Code Header',
    FourDeclaredHSCodeDes: '4 Digit HS Code Header Description',
    PCAStandard: 'PCA Standard',
    DGD: 'DGD',
    FoundGoodsCondition: 'Found Goods Condition',
    Quantity: 'Quantity',
    MesurementUnit: 'Mesurement Unit',
    CertificateStLine: 'Line Declared Fob Value (Ref)',
    ProductReferenceSR: 'Product Reference SR',
    ProductPosition: 'Product Reference Line Position',
    ProductExpiry: 'Product Expiry Date',
    ICCountry: 'IC Country',
    FactoryDate: 'Factory Audit Done Date',
    TransportDocumentReference: 'Transport Document Reference',
    TestReportNumber: 'Test Report Number',
    TestReportDate: 'Test Report Date',
    FinalInvoiceNumber: 'Final Invoice Number',
    FinalInvoiceDate: 'Final Invoice Date',
    DischargePlace: 'Discharge Place',
    index: 'inde',
    rfc: 'RFC #',
    idfno: 'IDF No.'
  },
  COCINVReport: {
    FirstIssuedDate: 'First Issued Date',
    LastIssuedDate: 'Last Issued Date',
    createdAt: 'Created At',
    certDate: 'Certificate Date',
    exportName: 'Exporter Name',
    exportCountry: 'Exporter Country',
    importName: 'Importer Name',
    contract: 'Contract',
    SRType: 'SR Type',
    SRStatus: 'SR Status',
    Seller: 'Seller',
    applicant: 'Applicant',
    Buyer: 'Buyer',
    RemoteInspection: 'Remote Inspection',
    PhysicalInspection: 'Physical Inspection',
    POICountry: 'POI Country',
    POICity: 'POI City',
    CoC: 'CoC #',
    countrySupply: 'Country Of Supply',
    PACRoute: 'PAC Route',
    HS: 'Main HS Chapter',
    CoCCategory: 'CoC Category',
    CoCType: 'CoC Type',
    CoCStatus: 'CoC Status',
    CoCPrintedByOffice: 'CoC Printed By Office',
    CoCIssuedByOffice: 'CoC Issued By Office',
    finalNumber: 'CoC. Final Invoice Numbers',
    CoCFOBCurrency: 'CoC FOB Currency',
    CoCFOBCValue: 'CoC FOB Declared Value',
    CoCFOBCValueRef: 'CoC FOB Declared Value(ref)',
    WSCTInvoiceNumber: 'WSCT Invoice Number',
    WSCTInvoiceIndexedDate: 'WSCT Invoice Date',
    InvoicingAffiliate: 'Invoicing Affiliate',
    InvoiceCurrency: 'Invoice Currency',
    InvoiceAmount: 'Invoice Amount',
    InvoiceAmountRef: 'Invoice Amount(ref)',
    CertificateStatus: 'Certificate Status',
    CertificateDate: 'Certificate Date',
    CertificateStatusDate: 'Certificate Status Date',
    CertifIssued: 'CertifIssued',
    SalesOffice: 'Sales Office',
    Month: 'Month',
    rate: 'Rate',
    IC: 'IC',
    index: 'index',
    Sr: 'SR #',
    idf: 'IDF #'
  },
  intervention: {
    auditReportAt: 'Audit Report At',
    IAFAssessAt: 'IAF Assess At',
    FirstIssuedDate: 'First Issued Date',
    LastIssuedDate: 'Last Issued Date',
    createdAt: 'Created At',
    certDate: 'Certificate Date',
    contract: 'Contract',
    SRId: 'SR Id',
    IDFNumber: 'IDF Number',
    SoRSoLRef: 'SoR/SoL Ref.',
    PCARoute: 'PCA Route',
    exportName: 'export Name',
    exportCountry: 'export Country',
    importName: 'import Name',
    IAFNbItem: 'IAF Nb Item',
    firstIAFDate: 'First lAF Date',
    firstIAFBy: 'First lAF By',
    InspectionQuality: 'Inspection Quality',
    RemoteInspection: 'Remote Inspection',
    PhysicalInspection: 'Physical Inspection',
    EInspectionReportYN: 'EInspection Report Y/N',
    CargoType: 'Cargo Type',
    CoC: 'CoC #',
    cargoType: 'cargo Type',
    Container: 'Container',
    WSCTSealUsed: 'WSCT Seal Used',
    SealNumber: 'Seal Number',
    Certificate: 'Certificate #',
    InterventionCategory: 'Intervention Category',
    InterventionLevel: 'Intervention Level',
    InspectorName: 'Inspector Name',
    InspectorCountry: 'Inspector Country',
    InspectorBranch: 'Inspector Branch',
    SchedulerName: 'Scheduler Name',
    ResourceType: 'Resource Type',
    InterventionRequestedDate: 'Intervention Requested Date',
    InterventionScheduledDate: 'Intervention Scheduled Date',
    DateofIntervention: 'Date of Intervention',
    InterventionDuration: 'Intervention Duration',
    InterventionResult: 'Intervention Result',
    InterventionResultDetails: 'Intervention Result Details',
    POIName: 'POIName',
    POlCountry: 'POl Country',
    POIPostalCode: 'POI Postal Code',
    POlCity: 'POl City',
    POlProvince: 'POl Province',
    POlInterventionarea: 'POl Intervention area',
    POladdress: 'POl address',
    InspReportIndexeddate: 'Insp. Report Indexed date',
    InspReportIssueddate: 'Insp.  Report Issued date',
    inspectionreportexpirydate: 'inspection report expiry date',
    COCDATE: 'COC DATE',
    InspReportReviewdate: 'Insp.  Report Review date',
    Discrepanciesfound: 'Discrepancies found',
    InterventionReviewDoneDate: 'Intervention Review Done Date',
    InterventionReviewDoneBy: 'Intervention Review Done By',
    AuditReportIndexed: 'Audit Report Indexed',
    ICOffice: 'IC Office',
    index: 'index'
  },
  SRReport: {
    FirstIssuedDate: 'First Issued Date',
    LastIssuedDate: 'Last Issued Date',
    createdAt: 'Created At',
    certDate: 'COC Issue Date',
    exportName: 'export Name',
    exportCountry: 'export Country',
    importName: 'import Name',
    contract: 'Contract',
    CreatedthroughOnlinePortal: 'Created through Online Portal',
    SrType: 'Sr Type',
    SRforpartial: 'SR for partial',
    SRId: 'SR Id',
    IDFNumber: 'IDFNumber',
    UCRNumber: 'UCR Number',
    SRStatus: 'SR Status',
    StatedInterventionRequired: 'Stated Intervention Required',
    SrReceptionDate: 'Sr Reception Date',
    Importer: 'Importer',
    lmporterContactName: 'lmporter Contact Name',
    ImporterContactTel: 'Importer Contact Tel',
    Exporter: 'Exporter',
    Applicant: 'Applicant ',
    ExporterCountry: 'Exporter Country',
    CountryofSupply: 'Country of Supply',
    Manufacturer: 'Manufacturer',
    ExporterCity: 'Exporter City',
    ManufacturerCountry: 'Manufacturer Country',
    LoadingPlace: 'Loading Place',
    DischargePlace: 'Discharge Place',
    NumberofLines: 'Number of Lines',
    DeclaredFOBValue: 'Declared FOB Value (ref)',
    CreatedDate: 'Created Date',
    CreatedBy: 'Created By',
    Orderconfirmeddate: 'Order confirmed date',
    OrderConfirmedBy: 'Order Confirmed By',
    FirstIAFDate: 'First IAF Date',
    FirstIAFBy: 'First IAF By',
    PCARoute: 'PCA Route',
    ICOffice: 'IC Office',
    ICname: 'IC name',
    index: 'index'
  },
  makeDraft: {
    certInfo: 'Certificate information ',
    unpdateInfo: 'Modify information ',
    submitDraft: 'Draft confirmation ',
    makeDraft: 'Make a draft ',
    rawDate: 'raw data ',
    newDate: 'New data ',
    draftRecord: 'Draft record ',
    remarks: 'Remarks ',
    pleaseRemarks: 'Please enter remarks ',
    version: 'version ',
    drafter: 'draftsman ',
    date: 'Draft time ',
    draftView: 'Draft Preview ',
    reOpen: 'open permissions ',
    reOpenTips: 'Enable inspector to edit inspection data? ',
    draftRemarks: 'Edit note',
    draftUnit: 'Editorial unit ',
    unit: 'unit ',
    newUnit: 'new unit ',
    draftResubmit: 'Draft confirmation ',
    draftResubmitTips: 'The operation indicates that the customer has confirmed that the relevant information about the draft is correct, and wsct confirms the operation on behalf of the customer to facilitate timely certification! ',
    description: 'Description ',
    PleaseDescription: 'Please enter a description ',
    saveDraft: 'Save draft'
  },
  parameterManagement: {
    name: 'Name',
    sort: 'Sort number',
    tsort: 'Sort',
    updateTime: 'Update Time',
    status: 'State',
    successUpdate: 'Update Success',
    riskType: 'Risk Type',
    backList: 'Return to List',
    description: 'Description',
    core: 'Core Definition',
    explanatory: 'Explanatory',
    creatTime: 'Creation Time',
    portName: 'Port Name',
    country: 'Country',
    simpleName: 'Regional Abbreviation',
    uplevel: 'Upper Levels',
    standardType: 'Standard Type',
    ClassFication: 'Standard Classification Code',
    StandarNumber: 'Standard Number',
    StandarYear: 'Standard Year',
    InternationalCode: 'ICS Code',
    rule: 'Laws And Regulations',
    SchemeType: 'Regulatory Scheme Type',
    language: 'Language',
    RegulatoryDescription: 'Standard/Regulatory Description',
    DocumentSource: 'Document Source',
    EquivalentStandards: 'Equivalent Standards',
    StandardTest: 'Standard Testing Items',
    StandardState: 'Standard State',
    InputCustoms: 'Please enter the customs slogan',
    InputDetail: 'Please enter a description',
    append: 'Increase',
    successmove: 'Successfully moved',
    successsave: 'Successfully saved',
    expand: 'Expand',
    retract: 'Retract',
    complete: 'Complete',
    type: 'Type',
    version: 'Version',
    customCode: 'Customs Slogan',
    basicInfo: 'Basic information',
    industybasicInfo: 'Industry Standard Basic Information（',
    annex: 'Standard equipment',
    industyStandard: 'Industry standard',
    sureDel: 'Are you sure you want to delete it?',
    PLname: 'Please enter a name',
    successAdd: 'Added successfully',
    PLtype: 'Please select a type',
    PLportname: 'Please enter the port name',
    PLcountry: 'Please enter the name of your country',
    hasChildren: 'Contains child nodes, please delete the child nodes.',
    alfirst: 'It is already the first one',
    allast: 'It is already the last one',
    PLcode: 'Please enter the abbreviation',
    Delsuccess: 'Successfully deleted',
    PLscode: 'Please select a standard classification code',
    PLsnumber: 'Please enter the standard number',
    PLsyear: 'Please select a standard year',
    PLnscode: 'Please enter the International Standard Classification Code',
    PLlanguage: 'Please enter the language',
    PLstatus: 'Please select standard status',
    PLhcode: 'Please fill in the customs code number',
    editingchange: 'Still in editing status, please complete the editing',
    PLriskd: 'Please enter a risk description',
    outputMonth: 'Export Monthly Report',
    FileList: 'File List',
    sortwarning: 'Sort the same, cannot move up',
    sortwarninga: 'Same sorting, cannot go down',
    PLselect: 'Please select Standard information to replace',
    resmassage: 'Superseded Standard Information',
    PLsdata: 'Please select a piece of data',
    notoverfour: 'Cannot exceed 4 characters',
    AttachmentIntegrity: 'Attachment integrity',
    Nation: 'Country/Organization/Association',
    Industry: 'Industry',
    All: 'All',
    part: 'part',
    Standards: 'Standards issuing authority',
    Create: 'Create source',
    NotUpload: 'A copy of the standard has not yet been uploaded',
    pleaseInputInt: 'Please input positive integer',
    ViewCopy: 'View copy',
    CopyPreview: 'Copy preview',
    BZCode: 'Standard library number',
    onlyPdf: 'You can only upload pdf files',
    waring: 'If the standard publishing organization is not selected, contact the IT principal to add IT',
    all: 'All',
    load: 'Port of loading',
    unload: 'Discharging port',
    pleaseInputRule: 'Please enter the regulations',
    pleaseDescription: 'Please enter a description of the standard/regulation',
    pleaseStandards: 'Please enter an equivalent standard',
    pleaseOpen: 'Please select Open Platform',
    noData: 'No data to export!',
    // 请输入整数
    pleaseInputIntn: 'Please enter a positive integer',
    // 该排序号不能小于0
    sortwarningb: 'The sort number cannot be less than 0',
    // 新增成功
    addsuccess: 'Added success',
    // 编辑成功
    editsuccess: 'Edited successfully',
    // 区号
    areaCode: 'Area code',
    openPlatform: 'Open platform',
    deletionReason: 'Deletion reason',
    overRange: 'Stay within range',
    changeAdjacent: 'You can switch places as long as you sort adjacent!',
    allType: 'All types',
    standardName: 'Standard',
    standardStatus: 'Status',
    sandardClassification: 'Classification'
  },
  COCExpenseVerification: {
    Dateoissuance: 'Date of issuance',
    FinalinvoiceFOB: 'Final Invoice FOB Value With currency',
    QuotationFOB: 'Quotation FOB Value with currency',
    FOBValue: 'FOB Value(USD)',
    COCFee: 'COC Fee(USD)',
    RoyaltyFee: 'Royalty Fee(USD)',
    Exporter: 'Exporter',
    Importer: 'Importer',
    CountryOfSupply: 'Country of supply',
    index: 'index',
    IDFNO: 'IDF No.',
    COCNO: 'COC No.',
    RFCNO: 'RFC No.',
    UCRNo: 'UCR No.',
    route: 'Route'
  },
  logs: {
    approvalLog: 'Approval log'
  },
  SRDailyPurchase: {
    SRNumber: 'SR number',
    title: 'title',
    Certification: 'Certification',
    COCDATE: 'COC DATE',
    Exporter: 'Exporter',
    Importer: 'Importer',
    Inspection: 'Inspection',
    payer: 'payer',
    LaboratoryTestingFee: 'Laboratory testing fee (RMB)',
    TOTALCOCFEEIncludingTax: 'Total COC fee including tax (RMB)',
    LaboratoryTestingFeeUsd: 'Laboratory testing fee(USD)',
    TOTALCOCFEEIncludingTaxUsd: 'Total COC fee including tax (USD)',
    Settlementmethod: 'Settlement method',
    Inspectionaddress: 'Inspection address',
    Inspectioncontactperson: 'Inspection contact person',
    Inspectioncontactpersontel: 'Inspection contact phone ',
    code: 'customs code',
    ProductDescription: 'Product description',
    CargoType: 'type of goods',
    ShipmentMethod: 'Shipment method',
    Supervision: 'Loading supervision',
    Inspectionnot: 'Inspectionnot',
    SubmissionTime: 'Submission time',
    invoiceNo: 'Invoice No.',
    route: 'Route',
    FobValue: 'FOB VALUE',
    COCFEE: 'COC FEE (RMB)',
    VATFEE: 'VAT FEE (RMB)',
    COCFee: 'COC Fee (USD)',
    VATFEEUSD: 'VAT FEE (USD)'
  },
  Scheduled: {
    Area_code: 'Area_code',
    Insp_start_date: 'Insp_start_date',
    Insp_end_date: 'Insp_end_date',
    Inspection_location: 'Inspection_location',
    Contact_person: 'Contact_person',
    Tel_email: 'Telephone/E-Mail',
    SRNumber: 'SR Number',
    Desc_goods: 'Desc_goods',
    Goods_type: 'Goods_type',
    Quantity: 'Quantity',
    Unit: 'Unit',
    Insp_StartTimeEndTime: 'nsp_Start Time/End Time',
    Container: 'Container',
    Kinds: 'Kinds',
    Country: 'Country',
    BoltSeal_no: 'Bolt Seal_no',
    EXPORTER: 'EXPORTER',
    IMPORTER: 'IMPORTER',
    Inspector: 'Inspector',
    IC_name: 'IC_name',
    IC_Office: 'IC_Office',
    HSCode1: 'HS Code-1(4)',
    HSCode2: 'HS Code-2(4)',
    Inspection_result: 'Inspection_result',
    Discrepancy_code: 'Discrepancy_code',
    Comment: 'Comment',
    Remark: 'Remark',
    InspectionRequirements: 'Inspection requirements',
    SubmissionTime: 'Submission time',
    RfcUpdatedAt: 'Rfc updated at',
    RfcStatus: 'Rfc status',
    RfcNotinStatus: 'Rfc notin status',
    Inspectiontime: 'Inspection time',
    index: 'index',
    Office: 'Office'
  },
  serviceDetail: {
    ExchangeRate: 'Exchange Rate USD/RMB',
    ExchangeRate2: 'Exchange Rate EUR/USD',
    COCFeeUSD: 'COC fee(USD)',
    LaboratoryTestingFeeUSD: 'Laboratory testing fee(USD)',
    ManufacturerLicensingFeeUSD: 'Manufacturer licensing fee(USD)',
    RegistrationFeeUSD: 'Registration fee(USD)',
    SamplingOfBulkShipmentsFeeUSD: 'Sampling of bulk shipments fee(USD)',
    ReInspectionFeeUSD: 'Re inspection fee(USD)',
    OverTimeFeeUSD: 'Over time fee(USD)',
    CancellationFeeUSD: 'Cancellation fee(USD)',
    CocDate: 'COC date',
    index: 'index',
    RFCNO: 'RFC NO.',
    CoCNO: 'COC NO.',
    Dateoissuance: 'Date of issuance',
    route: 'Route',
    FOBValue: 'FOB Value(USD)'
  },
  audit: {
    EXPORTER: 'EXPORTER',
    IMPORTER: 'IMPORTER',
    creat: 'SR creation date',
    Acceptance: 'IC accept date',
    dispatch: 'IC dispatch date',
    dispatchc: 'CC dispatch date',
    complete: 'AF completion date',
    dispatcher: 'Date of dispatch of inspector',
    upload: 'IRIP upload date',
    Auditcompleted: 'IR IP audit completion date',
    uploadFD: 'FD upload date',
    lastupload: 'Last file upload date',
    PTEcomplete: 'PTE completion date',
    firstDraft: 'First draft date',
    lastDraft: 'Last draft date',
    cocsign: 'COC date of issue',
    SoLNumber: 'SoR/SoL Number',
    Inspectionnot: 'Inspectionnot',
    InspectionTimedifference: 'Time difference in inspection report',
    draftTimedifference: 'Draft time difference',
    IssueStart: 'Release time',
    index: 'index',
    SR: 'SR#',
    IDF: 'IDF#',
    COC: 'COC#',
    UCR: 'UCR#',
    route: 'Route'
  },
  serviceData: {
    year: 'Year',
    month: 'Month',
    data: 'data',
    creat: 'Number of application forms created',
    submit: 'Number of application forms submitted',
    dispatch: 'Number of certificates issued',
    correct: 'Number of certificate revisions',
    Newcustomers: 'Number of new customers',
    dayservice: ' Data Summary',
    January: 'Jan.',
    February: 'Feb.',
    March: 'Mar.',
    April: 'Apr.',
    May: 'May.',
    June: 'Jun.',
    July: 'Jul.',
    August: 'Aug.',
    September: 'Sept.',
    October: 'Oct.',
    November: 'Nov.',
    December: 'Dec.',
    total: 'Total'
  },
  invoice: {
    clientName: 'Client Name',
    ContactPerson: 'Contact Person',
    ClientAddress: 'Client Address',
    download: 'Download pdf',
    modify: 'Modify INVOICE',
    generate: 'Generate INVOICE PDF',
    InspectionTimes: 'Factory inspection time',
    hide: 'hide',
    hideOrders: 'Hide proxy orders',
    hiddenCredential: 'Hidden certificate issuance data exceeding 3 months',
    hideNot: 'No Hidden',
    confirm: 'confirm',
    cancellation: 'cancellation',
    invoicingForm: 'Invoicing Form'
  },
  kebqueries: {
    index: 'index',
    SerialNumber: 'Serial Number',
    DocumentType: 'Document Type',
    From: 'From',
    RfcNumber: 'Rfc Number',
    CertNumber: 'Cert Number',
    UcrNumber: 'Ucr Number',
    IdfNumber: 'Idf Number',
    InvoiceNumber: 'Invoice Number',
    Query: 'Query',
    ConclusionStatus: 'Conclusion Status',
    DateOpened: 'Date Opened',
    CreationTime: 'Creation Time',
    Action: 'Action',
    reply: 'reply',
    add: 'add',
    QueryAnalysis: 'Query Analysis',
    QueryResponse: 'Query Response',
    LinkToUploads: 'LinkTo Uploads',
    PartnerQuery: 'Partner Query',
    QueriesCreate: 'Queries Create',
    Responses: 'Responses',
    ResponseFrom: 'Response From',
    ResponseDate: 'Response Date',
    remind: 'Cannot exceed 4000 characters',
    CustomerIdentification: 'Customer Identification',
    agent: 'Channel',
    Directcustomer: ' Direct Sales',
    version: 'Quotation version record',
    nothave: 'There is currently no data available',
    Aserialnumber: 'Please enter the serial number',
    Aqueryanalysis: 'Please enter a query analysis',
    Aresponseinquiry: 'Please enter a response to your inquiry'
  },
  exchangeRate: {
    OriginalCurrency: 'Original Currency',
    CurrencyAfterConversion: 'Currency after conversion',
    exchangeRate: 'Exchange Rate',
    state: 'State',
    operate: 'Operate',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    cancellation: 'Cancellation',
    confirm: 'Confirm',
    Telephone: 'Telephone',
    pleaseInput: 'Please Input',
    order: 'The order ',
    exporter: 'Exporter',
    importer: 'Importer',
    manufacturer: 'Manufacturer',
    abnormalCustomer: ' include risk customers, please accept carefully!'
  },
  workBench: {
    applyFor: 'Apply for',
    inspectGoods: 'Inspect goods',
    InternalControl: 'Internal control',
    contract: 'Contract',
    dispatchIC: 'Dispatch IC',
    signAndIssue: 'Sign and issue',
    selectLess100: 'The selected time range cannot exceed 100 days',
    issueCert: 'Issue a certificate',
    thisWeek: 'This week',
    currentMonth: 'Current month',
    newOrder: 'New order',
    newCustomer: 'New customer',
    inspectionBackend: 'WSCT inspection management backend',
    orderStatistics: 'Order/Certificate Statistics'
  },
  supplement: {
    title: 'Title Filter',
    productUnit: 'Please Select Unit',
    pleaseSelect: 'Please Select',
    CertificateType: 'Certificate type',
    InspectionEndTime: 'Inspection end time',
    Customertype: 'Company name',
    upphoto: 'Allows uploading.doc.docx files',
    upfile: 'Allows uploading.PDF files',
    upfiles: 'Allows uploading.PDF files,Please upload the synthesized report',
    notquotation: 'No quotation generated pdf',
    InvoiceChanges: 'Invoice Changes',
    Yes: 'Yes',
    No: 'No',
    pleaseInput: 'Please Input',
    pleaseUp: 'Please upload the inspection report',
    pleaseUpPhotes: 'Please upload photos',
    pleaseSelectIC: 'Please select the dispatch object',
    pleaseSelectIsInpection: 'Please choose whether to inspect the goods or not',
    pleaseSelectRoute: 'Please select Route',
    pleaseInputRemeks: 'Please enter remarks',
    pleaseSelectTime: 'Please select time',
    pleaseSelectLevel: 'Please select the inspection level',
    pleaseSelectcondi: 'Please select a product',
    pleaseSelectInspection: 'Please select the inspection result',
    pleaseInputKM: 'Please enter the distance',
    pleaseSelectcondition: 'Please select product identification criteria',
    pleaseSelectlocation: 'Please select an inspection location',
    pleaseSelectGoods: 'Please select goods',
    pleaseSelectCloses: 'Please select Closes Correctly',
    pleaseSelectResults: 'Please select Global Inspection Results',
    pleaseSelectPOI: 'Please select Inspection Result Left At POI',
    pleaseSelectSealUser: 'Please select Seal User',
    pleaseSelectSealData: 'Please select Seal Date',
    to: 'To',
    pleaseSelecStarttTime: 'Please select a start time',
    pleaseSelecEndtTime: 'Please select an end time',
    pleaseSelecTravelStarttTime: 'Please select a departure time',
    pleaseSelecTraveEndtTime: 'Please select an arrival time',
    pleaseSelectAudit: 'Please select Audit action',
    pleaseSelectAudits: 'Please select operation',
    pleaseInputAudit: 'Please enter a comment',
    istesting: 'Factory inspection or not',
    backTop: 'Back to top',
    updateTime: 'updateTime',
    receives: 'receive',
    confirmreceive: 'Are you sure to claim and lock this application form?',
    remind: 'Reminder: After locking, customers are temporarily unable to edit application forms on the client side',
    theSame: 'Do not upload duplicate files',
    quotationTime: 'Quotation time',
    pleaseInputRemark: 'Please enter remarks',
    batchPut: 'Batch modify exchange rates',
    notQuotation: 'There are orders in the list you have selected that have not been quoted yet',
    notCurrency: 'The order you have selected has different currencies',
    exchangerate: 'Change exchange rate to',
    modifyrate: 'Please enter the modified exchange rate',
    pleaseInputdelies: 'Attention: Batch modification of exchange rates for selected RFC orders will affect the quotations of all selected RFC orders. Are you sure you want to make the modification?',
    pleaseInputa: 'Please enter the correct exchange rate',
    pleaseSelectSample: 'Please select the products you want to sample',
    pleaseSelectExchangeRate: 'Please select the order for which the exchange rate needs to be changed in bulk',
    noApplyType: 'The application type is inconsistent',
    noApplyTypeCountry: 'Different countries apply for different types of applications',
    notSQLSORFalg: 'SOL、SOR type orders do not need to change the exchange rate in bulk'
  },
  // 沙特
  SaudiArabia: {
    // 各方信息
    InformationParties: 'Information of All Parties',
    manufacturerDetails: 'Manufacturer Details',
    importerDetails: 'Importer/Authorized Representative Details',
    factoryDetails: 'Name of Factory Details',
    inspectionPlace: 'Place of Inspection',
    importer: 'Importer',
    clear: 'Clear',
    chooseHistoricalData: 'Choose historical data',
    copyManufacturer: 'Copy Manufacturer',
    copyImporter: 'Copy Importer',
    country: 'Country',
    SCNo: 'SC No.',
    companyNameEn: 'Company Name(EN)',
    city: 'City',
    address: 'Address',
    contactName: 'Contact Name',
    telephone: 'Telephone',
    telephonePlaceholder: 'Example: +86-10-8888888 or 86-010888888',
    email: 'Email',
    warehouseName: 'Warehouse Name',
    warehouseNameEn: 'Warehouse Name(EN)',
    zoneProvince: 'Zone/Province',
    GoodsAvailableDate: 'Goods Available For Inspection As From',
    pleaseSelectRfcType: 'Please select the Application For first',
    // 产品信息
    productInfo: 'Product Information',
    productName: 'Product Name ',
    trademarkBrand: 'Trademark/Brand',
    typeModel: 'Type/Model No.',
    HSCode: 'HS(tariff) code',
    otherInformation: 'Other information',
    otherAccessory: 'Other Accessory',
    // 附件
    addTransactionDocument: 'Add Transaction Document',
    AttachmentsAreRequired: 'Attachments are required: the previous PCOC/SC certification agreement form, Importer Declaration of Conformity (DOC), manufacturer business registration certificate, product safety test report, etc., can be downloaded/viewed by clicking ', // 需提供附件：有前面的PCOC/SC认证协议表格，进口商合格申明（DOC），制造商商业登记证，产品安全测试报告等，可点击“”下载/查看
    fileType: 'File Type',
    pleaseSureApplicationInfo: 'Please be sure to take the information of the application form seriously, to avoid misreporting and testing progress, please be sure to fill in carefully. (After filling in, give a signed and stamped PDF file at the same time)' // 请一定要认真对待申请表信息，避免眈误报告和测试的进度，请一定要认真填写。(填写后，同时要给到签字盖章的PDF档)
  },
  // 风险报告
  risk: {
    customer: 'Customer',
    product: 'Product',
    POI: 'POI',
    riskType: 'Risk Type',
    updateTime: 'Update time'
  },
  riskReport: {
    riskDetail: 'Risk detail',
    riskManagementReport: 'Risk management report',
    companyInfo: 'Company info',
    baseInfo: 'Base info',
    attachment: 'Attachment',
    productPhoto: 'Product photo',
    printedName: 'Printed name',
    approvalProcess: 'Approval process',
    againstRole: 'Against role',
    associateRfc: 'Associate RFC'
  },
  // 汽车验货
  inspectionCar: {
    ToBeUploadedOnlineAutomotive: 'To be uploaded online (automotive category)',
    INSPECTIONREPORT: 'INSPECTION REPORT',
    NONNEGOTIABLE: 'NON NEGOTIABLE',
    // RFC
    RFCNo: 'RFC #',
    SHIPMENT: 'SHIPMENT#',
    INSPNO: 'INSP NO.',
    ISSUINGOFFICE: 'ISSUING OFFICE',
    DELIVERYOFFICE: 'DELIVERY OFFICE',
    INSPECTOR: 'INSPECTOR',
    // 1-COMMERCIAL TRANSACTION REFERENCE
    COMMERCIALTRANSACTIONREFERENCE: 'COMMERCIAL TRANSACTION REFERENCE',
    EXPORTTO: 'EXPORT TO',
    stIDNUMBER: '1st ID NUMBER',
    DOC: 'DOC#',
    DOCDATE: 'DOC DATE',
    EXPORTERREF: 'EXPORTER REF.',
    EXPORTER: 'EXPORTER',
    CITYCOUNTRY: 'CITY*COUNTRY',
    PHONE: 'PHONE',
    GPS: 'GPS',
    // 2-GENERAL DESCRIPTION OF THE GOODS
    GENERALDESCRIPTIONOFTHEGOODS: 'GENERAL DESCRIPTION OF THE GOODS',
    REMOTE: 'REMOTE',
    FCL: 'FCL',
    NOOFCONTAINERS: 'NO.OF CONTAINERS',
    REINSPECTON: 'RE-INSPECTON',
    TRANSPORTBY: 'TRANSPORT BY',
    Thefirst4digits: 'The first 4 digits of the cabinet number',
    Thelast7digits: 'The last 7 digits of the cabinet number',
    Locknumber: 'Lock number',
    // 3-PLACE OF INSPECTION & 4-INSPECTION RESULTS
    PLACEOFINSPECTION: '3-PLACE OF INSPECTION & 4-INSPECTION RESULTS',
    TYPE: 'TYPE',
    NAME: 'NAME',
    ADDRESS: 'ADDRESS',
    CITY: 'CITY',
    COUNTRY: 'COUNTRY',
    CONTACT: 'CONTACT',
    EMAIL: 'E-MAIL',
    INSPECTIONRESULTS: 'INSPECTION RESULTS',
    SATISFACTORY: 'SATISFACTORY',
    SATISFACTORYWITHRESERVES: 'SATISFACTORY WITH RESERVES',
    UNSATISFACTORY: 'UNSATISFACTORY',
    // 5-REPORTING
    REPORTING: '5-REPORTING',
    indiccateAllFindings: 'indiccate all findings: damaged, repaired, replaced or rejected goods / packing, free of charge goods, etc.',
    FINDING: 'FINDING',
    DESCRIPTION: 'DESCRIPTION',
    CORRECTED: 'CORRECTED',
    CODE: 'CODE',
    PHOTOS: 'PHOTOS',
    // 6-WSCT MARKS & 6.1-CARGO TYPE & 7-QUANTITY & 8-PACKAGING & 9-PACKING DETAILS & SHIPING MARKS & 10-QUALIT
    WSCTMARKS_QUALIT: '6-WSCT MARKS & 6.1-CARGO TYPE & 7-QUANTITY & 8-PACKAGING & 9-PACKING DETAILS & SHIPING MARKS & 10-QUALIT',
    WSCTMARKSCARGOTYPE: 'WSCT MARKS & CARGO TYPE',
    WSCTMARKS: 'WSCT MARKS',
    GROSSWEIGHT: 'GROSS WEIGHT',
    CARGOTYPE: 'CARGO TYPE',
    Vehicle: 'Vehicle',
    QUANTITY: 'QUANTITY',
    NETWEIGHT: 'NET WEIGHT',
    UNIT: 'UNIT',
    VOLUME: 'VOLUME',
    PACKAGING: 'PACKAGING',
    // PACKING DETAILS & SHIPPING MARKS
    PACKINGDETAILS_SHIPPINGMARKS: 'PACKING DETAILS & SHIPPING MARKS',
    PACKINGDETAILS: 'PACKING DETAILS',
    SHIPPINGMARKS: 'SHIPPING MARKS',
    QUALITY: 'QUALITY',
    STATEOFGOODS: 'STATE OF GOODS',
    NEW: 'NEW',
    USED: 'USED',
    STOCKLOT: 'STOCK LOT',
    RECONDITIONED: 'RECONDITIONED',
    YES: 'YES',
    NO: 'NO',
    NA: 'NA',
    IFNOWHY: 'IF NO. WHY?',
    // 11-DESCRIPTION OF THE GOODS
    DESCRIPTIONOFTHEGOODS: 'DESCRIPTION OF THE GOODS',
    QUANTITYORIGN: 'QUANTITY ORIGN',
    CONFORMTOPICL: 'CONFORM TO PICL',
    GISPR116SAMPLING: 'GIS PR 116 SAMPLING',
    SAMPLESFORTEST: 'SAMPLES FOR TEST',
    // 12-CONTAINERS
    CONTAINERS: '12-CONTAINERS',
    SIZE2040: 'SIZE:20-40',
    TypeDryReeferOpen: 'Type: Dry-Reefer-Open top _Ventilated-Flat-Tank',
    Condition: 'Condition:1-Very good 2-Good 3-Average 4-Poor 5-Very poor',
    LINE: 'LINE',
    NUMBER: 'NUMBER',
    SIZE: 'SIZE',
    SEALED: 'SEALED',
    SEALNB: 'SEAL NB',
    OTHERSEALNO: 'OTHER SEAL NO.',
    CONDITION: 'CONDITION',
    TEMP: 'TEMP °C',
    NOOFPACKAGES: 'NO.OF PACKAGES',
    // 13-DOCUMENTS RECEIVED DURING INSPECTION, ANNEXED TO THIS REPORT
    DOCUMENTSRECEIVED: '13-DOCUMENTS RECEIVED DURING INSPECTION, ANNEXED TO THIS REPORT',
    OTHER: 'OTHER',
    // 14-CONTACT NAME ON INSPECTION SITE
    CONTACTNAMEONINSPECTIONSITE: 'CONTACT NAME ON INSPECTION SITE',
    SIGNATURESTAMP: 'SIGNATURE STAMP',
    TOTALNBOFDAYS: 'TOTAL NB OF DAYS',
    STARTTIME: 'START TIME',
    LASTINSPECTIONDATE: 'LAST INSPECTION DATE',
    INSPECTORSIGNATURE: 'INSPECTOR SIGNATURE',
    SIGNATURE: 'SIGNATURE',
    DURATION: 'DURATION',
    ENDTIME: 'END TIME',
    HOURS: 'HOURS',
    APPENDICESSENTWITHTHISREPORT: 'APPENDICES SENT WITH THIS REPORT',
    DESCRIPTIONOFTHEGOODSCont: 'DESCRIPTION OF THE GOODS(Cont.)',
    ITEM: 'ITEM',
    ORIGN: 'ORIGN',
    // 15-INSPECTION METHOD
    INSPECTIONMETHOD: '15-INSPECTION METHOD',
    GISPR116ANDPROGRAMDATA: 'GIS PR 116 AND PROGRAM DATA SHEET REVISION NO.',
    SAMPLINGPLAN: 'SAMPLING PLAN',
    NumberOfLineItems: 'Number of line items',
    ADDITIONALSAMPLING: 'ADDITIONAL SAMPLING',
    VERIFICATIONOFQUANTITY: 'VERIFICATION OF QUANTITY',
    COUNT: 'COUNT',
    MEASUREMENT: 'MEASUREMENT',
    CALIBRATIONEVIDENCECOLLECTED: 'CALIBRATION EVIDENCE COLLECTED',
    MNSFORMYSAFETY: '"2MNS FOR MY SAFETY" DONE, CHECK LIST COMPLETED',
    // 16-OBSERVATIONS
    OBSERVATIONS: '16-OBSERVATIONS',
    // 17-FILE CONSOLIDATED WITH OTHER FILES
    FILECONSOLIDATEDWITHOTHERFILES: 'FILE CONSOLIDATED WITH OTHER FILES',
    OTHERFILESREF: 'OTHER FILES REF',
    // 18-TIME AND EXPENSES
    TIMEANDEXPENSES: '18-TIME AND EXPENSES',
    KM: 'KM',
    TRANSPORTTIME: 'TRANSPORT TIME',
    ADMINISTRATIVETIME: 'ADMINISTRATIVE TIME',
    Day: 'Day (dd/mm/yyyy)',
    // INSPECTORSIGNATURE: 'INSPECTOR SIGNATURE',
    // Vehicle Description
    VehicleDescription: 'Vehicle Description',
    YEAR: 'YEAR',
    MAKE: 'MAKE',
    ModelSeries: 'Model / Series',
    BodyStyle: 'Body Style',
    VehicleCondition: 'Vehicle condition',
    VINPlate: 'VIN Plate',
    GravedChassisNo: 'Graved chassis no',
    SteeringWheel: 'Steering wheel',
    NbrOfCylinders: 'Nbr of Cylinders',
    Mileage: 'Mileage(Km/Miles)',
    EngineFuel: 'Engine (Fuel)',
    EngineCapacity: 'Engine capacity(cc)',
    GVW: 'GVW (Kg)',
    ExteriorColour: 'Exterior colour',
    NbrOfDoors: 'Nbr of Doors',
    Transmission: 'Transmission',
    LicencePlateNumber: 'Licence plate number',
    // VIN
    VIN: 'VIN',
    ModelCode: 'Model Code (from ViN plate)',
    GW: 'GW (Kg)',
    NbrOfSeats: 'Nbr of Seats',
    CarbonMonoxide: 'Carbon monoxide in exhaust gas',
    Speeds: 'Speeds',
    // Vehicle Condition
    Vehicle_Condition: 'Vehicle Condition',
    Good: 'Good',
    Average: 'Average',
    Poor: 'Poor',
    Suspension: 'Suspension',
    // Transmission: 'Transmission',
    ElectricalSystem: 'Electrical system',
    Engine: 'Engine',
    Tyres: 'Tyres',
    Dashboard: 'Dashboard',
    DirectionLights: 'Direction lights',
    Brakes: 'Brakes',
    Handbrake: 'Handbrake',
    Wipers: 'Wipers',
    LockingDevices: 'Locking devices',
    SpareTyre: 'Spare tyre',
    Inside: 'Inside',
    FrontLights: 'Front lights',
    Doors: 'Doors',
    Windows: 'Windows',
    Trunk: 'Trunk',
    Hood: 'Hood',
    Battery: 'Battery',
    Seats: 'Seats',
    Rearlights: 'Rearlights',
    Brakelights: 'Brakelights',
    ReportTheExactLetter: 'Report the exact letter on the picture',
    SForScratch: 'S for scratch',
    DForDent: 'D for Dent',
    RForReplacement: 'R for Replacement',
    MForMissing: 'M for Missing',
    // CALIBRATIONEVIDENCECOLLECTED: 'CALIBRATION EVIDENCE COLLECTED',
    InspectionComments: 'Inspection comments',
    // Vehicule Equipment(list all accessories and additional equipment)
    VehiculeEquipment: 'Vehicule Equipment(list all accessories and additional equipment)',
    AirConditioning: 'Air conditioning',
    AMFMStereo: 'AM/FM Stereo',
    AlarmSystem: 'Alarm system',
    Sunroof: 'Sunroof',
    other: 'other',
    InspectionPhoto: 'Inspection Photo',
    uploadText: 'Please upload the.doc/.docx/.zip file and it should be less than 1G',
    allowUploading: 'Allow uploading .doc , .docx, .zip files'
  }
}
